<template>
  <b-field
    :label="label"
    :type="inputType"
    :message="errorMessage"
    :class="{ required: isRequired }"
  >
    <Editor
      v-model="innerValue"
      :api-key="'28v2bjv9q5roshia1gkxlfoglmgj1ud8h300o6byfcvtb22x'"
      :init="{
        height,
        plugins: this.plugins,
        toolbar: this.toolbar,
        menubar: this.menubar,
      }"
      :disabled="this.disabled"
    />
  </b-field>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';

export default {
  components: {
    Editor,
  },
  props: {
    label: String,
    plugins: String,
    toolbar: String,
    menubar: String,
    errors: Array,
    isRequired: Boolean,
    value: {
      type: null,
    },
    disabled: Boolean,
    height: {
      type: Number,
      default: () => 400,
    },
  },
  computed: {
    inputType() {
      if (this.errors && this.errors.length) return 'is-danger';
      return null;
    },
    errorMessage() {
      if (!this.errors || !this.errors.length) return null;
      return this.errors[0];
    },
  },
  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  data: () => ({
    innerValue: null,
  }),
  mounted() {
    this.innerValue = this.value;
  },
};
</script>

<style></style>
