<template>
  <div id="app">
    <loading-page v-if="isLoading"></loading-page>
    <router-view v-if="!isLoading" />
  </div>
</template>

<script>
import LoadingPage from '@/views/Loading.vue';
import { AUTH_TOKEN } from '@/store/actions/auth';
import { mapGetters } from 'vuex';
import UserTypesEnum from './enums/UserTypesEnum';
import ROUTE_GROUPS from '@/constants/route-groups.constant';

export default {
  components: {
    LoadingPage,
  },
  data: () => ({
    USER_TYPES: UserTypesEnum,
    ROUTE_GROUPS: ROUTE_GROUPS,
  }),
  computed: {
    isLoading() {
      return this.$store.state.auth.loading;
    },
    ...mapGetters(['userType', 'hasPagarmeAccount', 'isAuthenticated']),
  },
  methods: {
    includedIn(needle, haystack) {
      return haystack.includes(needle);
    },
    redirectUser() {
      console.log(this.isAuthenticated);

      if (!this.isAuthenticated) {
        this.$router.go('/login');
        return;
      }

      if (!this.hasPagarmeAccount) {
        this.$router.push('/schedule');
        return;
      }

      const route = this.$router.history._startLocation;

      if (this.includedIn('login', route)) {
        this.$router.push('/schedule');
        return;
      }

      if (this.includedIn(route, this.ROUTE_GROUPS.general_register)) {
        this.$router.push('/general-register');
        return;
      }

      if (this.includedIn(route, this.ROUTE_GROUPS.models)) {
        this.$router.push('/models');
        return;
      }

      if (this.includedIn(route, this.ROUTE_GROUPS.users)) {
        this.$router.push('/users');
        return;
      }

      this.$router.push(route);

      return;
    },
  },
  mounted() {
    this.$store
      .dispatch(AUTH_TOKEN)
      .then(() => {
        this.redirectUser();
      })
      .catch(() => {
        switch (this.$route.name) {
          case 'register': {
            this.$router.push(this.$route.fullPath);
            break;
          }
        }
      });
  },
};
</script>

<style lang="scss">
@import 'styles/_global.scss';

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
}
</style>
