import Api from './api.service';

const ENDPOINT = 'vouchers';

export default {
  store: (data) => Api.post(`${ENDPOINT}`, data),
  get: (params = {}) =>
    Api.get(`${ENDPOINT}`, {
      params,
    }),
  getId: (id, params = {}) =>
    Api.get(`${ENDPOINT}/${id}`, {
      params,
    }),
  update: (id, data) => Api.post(`${ENDPOINT}/${id}`, data),
  destroy: (id) => Api.delete(`${ENDPOINT}/${id}`),
  getActiveVoucher: (params = {}) =>
    Api.get(`${ENDPOINT}/active`, {
      params,
    }),
};
