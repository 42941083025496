import Api from './api.service';

const ENDPOINT = 'payment-types';

export default {
  get: (params = {}) => Api.get(`${ENDPOINT}`, { params }),
  getId: (id) => Api.get(`${ENDPOINT}/${id}`),
  store: (procedure) => Api.post(`${ENDPOINT}`, procedure),
  update: (id, data) => Api.put(`${ENDPOINT}/${id}`, data),
  destroy: (id) => Api.delete(`${ENDPOINT}/${id}`)
};
