import Clear from '@/layouts/Clear.vue';

export default {
  path: '/doctors',
  component: Clear,
  children: [
    {
      path: '',
      name: 'doctors',
      component: () => import('@/views/Doctors/DoctorsList.vue'),
    },
    {
      path: ':id?',
      name: 'doctors.form',
      component: () => import('@/views/Doctors/DoctorForm.vue'),
    },
  ],
};
