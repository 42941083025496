import Clear from '@/layouts/Clear.vue';

export default {
  path: '/payment-types',
  component: Clear,
  children: [
    {
      path: '',
      name: 'payment-types',
      component: () => import('@/views/PaymentType/PaymentTypeList.vue'),
    },
    {
      path: ':id?',
      name: 'payment-types.form',
      component: () => import('@/views/PaymentType/PaymentTypeForm.vue'),
    },
  ],
};
