import Api from './api.service';

const ENDPOINT = 'prescriptions';

export default {
  get: (scheduleId, params = {}) => Api.get(`${ENDPOINT}?filter[schedule_id]=${scheduleId}`, {params}),
  getPatientPrescriptions: (params = {}) =>
    Api.get(`${ENDPOINT}`, {params}),
  store: (data) => Api.post(`${ENDPOINT}`, data),
  update: (id, data) => Api.put(`${ENDPOINT}/${id}`, data),

  getModel: (clinicId, id) => Api.get(`${clinicId}/prescription-models/${id}`),
  getModels: (clinicId, params = {}) =>
    Api.get(`${clinicId}/prescription-models`, { params }),
  storeModel: (clinicId, data = {}) =>
    Api.post(`${clinicId}/prescription-models`, data),
  updateModel: (clinicId, id, data) =>
    Api.put(`${clinicId}/prescription-models/${id}`, data),
  destroyModel: (clinicId, id) =>
    Api.delete(`${clinicId}/prescription-models/${id}`),

  downloadPrescription: (id) => Api.get(`${ENDPOINT}/${id}/download-pdf`),
  sendPrescriptionToPatient: (id) =>
    Api.get(`${ENDPOINT}/${id}/send-to-patient`),
  getPrescriptionFileModels: () => Api.get(`prescription-file-models`),
  saveMemedPrescription: (data) =>
    Api.post(`${ENDPOINT}/save-memed-prescription`, data),
  getCid: () => Api.get(`prescription-cid`),
  getDrugs: () => Api.get(`prescription-drugs`)
};
