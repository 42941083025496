<template>
  <section class="schedule-availabilities">
    <b-loading :active.sync="isWeekdaysLoading" :is-full-page="false" />

    <EventsCalendar
      :disabledDates="disabledDates"
      :reloadCalendar="reloadCalendar"
      @onCurrentMonthChange="onMonthChange"
      @onSelectDate="onSelectedDateChange"
      @onCalendarReload="setCalendarReload"
    />

    <div class="schedule-availabilities__events custom-scroll">
      <b-loading :active.sync="isEventsLoading" :is-full-page="false" />
      <div
        class="schedule-availabilities__events__event"
        :class="{
          selected: selectedEvent ? selectedEvent.start === event.start : false,
        }"
        v-for="(event, idx) of events"
        :key="idx"
        @click="setSelectedEvent(event)"
      >
        {{ event.start }}
      </div>
    </div>

    <div
      v-if="selectedEvent && scheduleType != 1"
      class="schedule-availabilities__selected-event-address"
    >
      <p>Endereço do atendimento</p>
      <p>{{ selectedEventAddress }}</p>
    </div>

    <div class="schedule-availabilities__no-events" v-if="hasNoEvents">
      <p>
        {{ isSameDoctor ? 'Você' : 'Profissional selecionado' }} não tem nenhum
        horário disponível!
      </p>
      <b-button v-if="isSameDoctor" @click="goToTimetable" type="is-primary"
        >Cadastrar novos horários</b-button
      >
    </div>
  </section>
</template>

<script>
import moment from 'moment';
import SchedulesService from '../services/schedules.service';
import EventsCalendar from './EventsCalendar.vue';
import { mapGetters } from 'vuex';

export default {
  components: { EventsCalendar },
  name: 'schedule-availabilities',
  props: {
    doctorId: Number,
    procedureId: Number,
    scheduleType: Number,
    healthPlanId: Number,
  },

  data: () => ({
    disabledDates: [],
    selectedDate: moment().format('YYYY-MM-DD'),
    isWeekdaysLoading: false,
    reloadCalendar: false,
    isEventsLoading: false,
    events: [],
    selectedEvent: null,
    hasNoEvents: false,
    addressId: null,
  }),
  watch: {
    scheduleType() {
      this.setCalendarReload();
    },
    healthPlanId() {
      this.setCalendarReload();
    },
    addressId() {
      this.setCalendarReload();
    },
  },
  computed: {
    selectedEventAddress() {
      const address = this.selectedEvent.address;
      return `${address.street}, ${address.number} - ${address.cep}, ${address.city.name_with_state}`;
    },
    isSameDoctor() {
      return this.doctorId == this.$store.getters.doctorId;
    },
    ...mapGetters(['clinicId']),
  },
  methods: {
    goToTimetable() {
      this.$router.push('/timetable');
    },
    setSelectedEvent(event) {
      this.selectedEvent = event;

      this.$emit('onSelectEvent', {
        ...this.selectedEvent,
        date: this.selectedDate,
      });
    },
    setCalendarReload() {
      this.reloadCalendar = !this.reloadCalendar;
    },
    clearSelectedEvent() {
      this.selectedEvent = null;
      this.events = [];

      this.$emit('clearSelectedEvent');
    },
    onMonthChange(date) {
      this.clearSelectedEvent();
      this.disabledDates = [];
      this.events = [];
      this.selectedDate = date;
      this.getDoctorWeekdaysAvailable();
      this.$emit('onMonthChange');
    },
    onSelectedDateChange(date) {
      this.clearSelectedEvent();
      this.hasNoEvents = false;
      this.selectedEvent = null;
      this.selectedDate = date;
      this.loadAvailableDates();
    },
    loadAvailableDates() {
      this.isEventsLoading = true;

      const params = {
        'filter[clinic_id]': this.clinicId,
        'filter[doctor_id]': this.doctorId,
        'filter[health_plan_id]': this.healthPlanId,
        'filter[address_id]': this.addressId,
        appointment_type_id: this.scheduleType,
        selectedDate: this.selectedDate,
      };

      if (this.selectedProcedure) {
        params['filter[procedure_id]'] = this.selectedProcedure.id;
      }

      SchedulesService.getScheduleEvents(params)
        .then(({ data }) => {
          console.log(data);
          this.generateAvailableHours(data);
        })
        .finally(() => (this.isEventsLoading = false));
    },
    generateAvailableHours(events) {
      this.events = [];
      if (!events.length) this.hasNoEvents = true;

      events.forEach((schedule) => {
        const start = moment(schedule.start);
        const now = moment();

        if (start.isAfter(now)) {
          this.events.push({
            address: schedule.address,
            start: start.format('HH:mm'),
            standard_time: schedule.standard_time,
          });
        }
      });

      this.setSelectedEvent(this.events[0]);
    },
    getDoctorWeekdaysAvailable() {
      this.isWeekdaysLoading = true;

      const params = {
        'filter[doctor_id]': this.doctorId,
        'filter[schedule_type]': this.scheduleType,
        'filter[selected_date]': this.selectedDate,
        'filter[health_plan_id]': this.healthPlanId,
        'filter[address_id]': this.addressId,
      };

      SchedulesService.getAvailableWeekdays(params)
        .then(({ data }) => {
          const weekdays = data;

          if (!weekdays.length) this.hasNoEvents = true;
          if (weekdays.length) this.hasNoEvents = false;

          const initialDate = moment(this.selectedDate)
            .clone()
            .startOf('month');

          const endDate = moment(this.selectedDate).clone().endOf('month');

          while (initialDate.isBefore(endDate)) {
            if (
              !weekdays.includes(initialDate.weekday()) ||
              initialDate.isSameOrBefore(moment().subtract(1, 'day'))
            ) {
              this.disabledDates.push(initialDate.format('DD'));
            }

            initialDate.add(1, 'day');
          }
        })
        .finally(() => {
          this.isWeekdaysLoading = false;
        });
    },
  },
  mounted() {
    this.getDoctorWeekdaysAvailable();
  },
};
</script>

<style lang="scss" scoped>
.schedule-availabilities {
  &__events {
    margin-top: 30px;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    width: 100%;
    max-height: 300px;

    &__event {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 10%;
      margin-right: 10px;
      margin-top: 10px;
      height: 50px;

      background-color: #f5f9f9;
      border-radius: 4px;

      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
    }

    &__event:not(.selected):hover {
      cursor: pointer;
      color: var(--color-secondary);
    }
    &__event.selected {
      font-weight: bold;
      color: var(--color-secondary);
      border-color: var(--color-secondary);
      border-width: 2px;
    }

    &__event:hover {
      cursor: pointer;
    }
  }

  &__selected-event-address {
    margin-top: 45px;
    text-align: center;

    p {
      font-size: 1.1rem;
      letter-spacing: 1px;
    }

    p:first-child {
      font-weight: bold;
    }
  }

  &__no-events {
    text-align: center;
    p {
      font-size: 1.1rem;
      margin-bottom: 10px;
    }
  }
}
</style>
