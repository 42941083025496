import Vue from 'vue';

export default Vue.mixin({
  methods: {
    fileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    blobToFile(blob, filename) {
      blob.lastModifiedDate = new Date();
      blob.name = filename;
      return blob;
    },
    checkMaxDimension(file, maxWidth, maxHeight) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (event) => {
          const image = new Image();
          image.src = event.target.result;

          image.onload = () => {
            if (image.width > maxWidth || image.height > maxHeight)
              resolve(false);
            else resolve(true);
          };
        };
      });
    },
    checkMaxSize(file, maxSize) {
      return new Promise((resolve) => {
        const size = Math.round(file.size / 1024);

        if (size > maxSize) resolve(false);
        else resolve(true);
      });
    },
  },
});
