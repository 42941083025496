<template>
  <div class="schedule-value-modal">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Dados de pagamento</p>
      </header>
      <section class="modal-card-body">
        <b-loading :active.sync="isLoading" :is-full-page="false" />

        <div class="row mt-4">
          <div class="col-12">
            <b-field label="Tipo de pagamento">
              <b-select
                expanded
                icon="hand-holding-usd"
                placeholder="Selecionar"
                v-model="paymentTypeId"
                is-required
              >
                <option
                  v-for="paymentType in paymentTypes || []"
                  :key="paymentType.id"
                  :value="paymentType.id"
                >
                  {{ paymentType.name }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-12">
            <b-field label="Valor">
              <app-input
                v-model="value"
                placeholder="Informe um valor"
                :mask="MASKS.money"
                is-required
              >
              </app-input>
            </b-field>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-12">
            <b-field label="Data de pagamento">
              <b-datepicker
                style="width: 100%"
                trap-focus
                inline
                icon="calendar"
                :locale="'pt-BR'"
                v-model="paymentDate"
                placeholder="Selecionar data..."
                :date-formatter="bDateFormatter"
              >
              </b-datepicker>
            </b-field>
          </div>
        </div>
      </section>

      <footer class="modal-card-foot is-right">
        <b-button @click="$emit('close', false)" expanded type="is-text"
          >Cancelar</b-button
        >

        <b-button
          expanded
          type="is-secondary"
          @click="onClickChangeValue"
          :disabled="!value || !paymentTypeId || !paymentDate"
          >Salvar
        </b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import PaymentTypesService from '@/services/payment-types.service';
import MASKS from '@/constants/masks.constant';
export default {
  props: {
    oldValue: String,
    selectedPaymentTypeId: Number,
    selectedPaymentDate: String,
    hideSkipButton: Boolean,
  },
  data: () => ({
    MASKS: MASKS,
    value: null,
    paymentTypeId: null,
    paymentTypes: [],
    unmodifiedValue: null,
    paymentDate: null,
    isLoading: false,
  }),

  methods: {
    loadPaymentTypes() {
      this.isLoading = true;

      const params = {
        clinic_id: this.$store.getters.clinicId,
      };

      PaymentTypesService.get({ ...params })
        .then(({ data }) => {
          this.paymentTypes = data;
        })
        .finally(() => (this.isLoading = false));
    },
    onClickChangeValue() {
      this.$emit('close', {
        newValue: this.unmask(this.value),
        paymentTypeId: this.paymentTypeId,
        paymentDate: this.paymentDate,
      });
    },
    unmask(value) {
      if (!value) return value;

      const realValue = value.split('R$').pop();
      return realValue.trim().replaceAll('.', '').replace(',', '.');
    },
  },
  mounted() {
    this.value = parseFloat(this.oldValue).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    this.unmodifiedValue = parseFloat(this.oldValue).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    this.paymentTypeId = this.selectedPaymentTypeId;
    this.paymentDate = this.selectedPaymentDate
      ? new Date(this.selectedPaymentDate)
      : null;

    this.loadPaymentTypes();
  },
};
</script>

<style lang="scss" scoped>
.modal-card {
  width: 100%;
}
.alert {
  margin-bottom: 10px;
  color: gray;
}
</style>
