import Api from './api.service';
import groupBy from 'lodash/groupBy';

const ENDPOINT = 'schedules';

export default {
  get: (params = {}) =>
    Api.get(`${ENDPOINT}`, {
      params,
    }),
  getId: (id, params = {}) =>
    Api.get(`${ENDPOINT}/${id}`, {
      params,
    }),
  store: (data) => Api.post(`${ENDPOINT}`, data),
  getAvailabilities: (doctorId) =>
    Api.get('available-doctor-schedules', {
      params: { doctor_id: doctorId },
    }).then(({ data }) => groupBy(data, 'weekday')),
  storeAvailability: (data) => Api.post('available-doctor-schedules', data),
  deleteAvailability: (id) => Api.delete(`available-doctor-schedules/${id}`),
  cancelSchedule: (id, data) =>
    Api.patch(`${ENDPOINT}/${id}/cancel`, {
      ...data,
    }),
  export: (params) => {
    return Api.get(`${ENDPOINT}/export`, { params });
  },
  getCheckouUrl: (scheduleId) => {
    return Api.get(`${ENDPOINT}/${scheduleId}/get-checkout-link`);
  },
  getScheduleList: (params = {}) => {
    return Api.get(`doctors/schedule-list`, { params });
  },
  getScheduleMeetRecords: (scheduleId) => {
    return Api.get(`${ENDPOINT}/${scheduleId}/meet-records`);
  },
  getAvailableScheduleTypes: () => Api.get('available-doctor-schedules/types'),
  getServiceLocations: (clinicId) => {
    return Api.get('available-doctor-schedules/service-locations', {
      params: { clinic_id: clinicId },
    });
  },
  validateDate: (params = {}) => {
    return Api.get(`${ENDPOINT}/validate-dates`, {
      params,
    });
  },
  getStatus: () => {
    return Api.get(`${ENDPOINT}/get-status`);
  },
  paymentDropOff: (id) => {
    return Api.put(`${ENDPOINT}/payment-drop-off/${id}`);
  },
  paymentUnconfirm: (id) => {
    return Api.put(`${ENDPOINT}/payment-unconfirm/${id}`);
  },
  updateStatus: (id, params = {}) => {
    return Api.put(`${ENDPOINT}/update-status/${id}`, params);
  },
  updatePayment: (id, params = {}) => {
    return Api.patch(`${ENDPOINT}/update-payment/${id}`, params);
  },
  getBlock: (params = {}) => Api.get('block-doctor-schedules', { params }),
  storeBlock: (data) => Api.post('block-doctor-schedules', data),
  deleteBlock: (id) => Api.delete(`block-doctor-schedules/${id}`),
  getPayment(chargeId) {
    return Api.get(`schedule-juno-charges/${chargeId}`);
  },
  reschedule(scheduleId, data) {
    return Api.post(`${ENDPOINT}/${scheduleId}/reschedule`, data);
  },
  getScheduleCancelSituation(scheduleId) {
    return Api.get(`${ENDPOINT}/${scheduleId}/cancel-situation`);
  },
  getAvailableWeekdays(params = {}) {
    return Api.get('schedules-availabilities/weekdays', {
      params,
    });
  },
  getScheduleEvents(params = {}) {
    return Api.get(`schedules-availabilities/schedules`, {
      params,
    });
  },
  confirmSchedule: (scheduleId) => Api.patch(`schedules/confirm/${scheduleId}` ),

  updateProcedure: (id, params = {}) => {
    return Api.patch(`${ENDPOINT}/update-procedure/${id}`, params);
  },

  getTypeSchedules: (params = {}) => Api.get('get-schedule-types', { params }),
};
