<template>
  <div class="balance-transfer-modal">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ isPix ? 'Chave pix para o pagamento' : 'Link para o pagamento' }}
        </p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <div class="link">
          <app-input v-model="url"> </app-input>
          <b-button @click="copyLink" type="is-primary"> Copiar </b-button>
        </div>
      </section>

      <footer class="modal-card-foot is-right">
        <b-button @click="$emit('close', true)" expanded type="is-text"
          >Fechar</b-button
        >
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    url: String,
    isPix: Boolean,
    chargeId: Number || null,
  },
  data: () => ({}),
  computed: {},
  methods: {
    copyLink() {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(this.url).then(() => {
          this.$buefy.snackbar.open({
            message: `${
              this.isPix ? 'Chave copiada' : 'Link copiado'
            } com sucesso!`,
            type: 'is-success',
          });
        });
      } else {
        this.$buefy.snackbar.open({
          message: `Não foi possivel copiar ${
            this.isPix ? 'a chave' : 'o link'
          }. Navegador não compatível com a funcionalidade, por favor, copie o link manualmente.`,
          type: 'is-warning',
          duration: 5000,
        });
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.balance-transfer-modal {
  .modal-card {
    width: 600px;

    .modal-card-title {
      text-align: center;
    }
    .modal-card-body {
      .link {
        font-size: 0.9rem;

        display: flex;
        justify-content: space-between;

        .field {
          width: 85%;
        }
      }

      .pix-timer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &__time {
          font-size: 3.5rem;
          font-weight: bold;
          letter-spacing: 1px;

          font-family: 'Poppins';

          &__min {
            font-size: 2rem;
            text-transform: uppercase;
          }

          transition: color 1s ease-out;
        }

        &__label {
          font-size: 1.1rem;
          letter-spacing: 1px;

          font-family: 'Poppins';
        }

        .expired {
          font-size: 1.6rem;
          text-transform: uppercase;
          color: var(--color-danger);
        }

        .normal {
          color: #3ec487;
        }

        .warning {
          color: #ffdc7d;
        }

        .danger {
          color: var(--color-danger);
        }
      }
    }
  }
}
</style>
