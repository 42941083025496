import Clear from '@/layouts/Clear.vue';

export default {
  path: '/procedures',
  component: Clear,
  children: [
    {
      path: '',
      name: 'procedures',
      component: () => import('@/views/Procedure/ProcedureList.vue'),
    },
    {
      path: ':id?',
      name: 'procedures.form',
      component: () => import('@/views/Procedure/ProcedureForm.vue'),
    },
  ],
};
