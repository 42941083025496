import Clear from '@/layouts/Clear.vue';

export default {
  path: '/recurrence-plans',
  component: Clear,
  children: [
    {
      path: '',
      name: 'recurrence-plans',
      component: () => import('@/views/RecurrencePlan/RecurrencePlanList.vue'),
    },
    {
      path: ':id?',
      name: 'recurrence-plan.form',
      component: () => import('@/views/RecurrencePlan/RecurrencePlan.vue'),
    },
  ],
};
