export default [
  {
    icon: 'calendar',
    label: 'Agenda',
    routeName: 'schedule',
    permission: ['read_schedule'],
  },
  {
    icon: 'clock',
    label: 'Horários',
    routeName: 'timetable',
    permission: ['read_timetables', 'read_doctor_timetable'],
  },
  {
    icon: 'clipboard',
    label: 'Consultas',
    routeName: 'schedules',
    permission: ['read_appointments'],
  },
  {
    icon: 'file-signature',
    label: 'Modelos',
    routeName: 'models',
    permission: [
      'read_medical_record_models',
      'read_prescription_models',
      'read_contract_model',
    ],
  },
  {
    icon: 'users',
    label: 'Usuários',
    routeName: 'users',
    permission: ['read_patients', 'read_secretaries', 'read_doctors'],
  },
  {
    icon: 'database',
    label: 'Cadastros',
    routeName: 'general-register',
    permission: [
      'read_contract',
      'read_procedures',
      'read_payment_types',
      'read_vouchers',
      'read_service_locations',
      'read_justifications',
      'read_custom_app_infos',
      'read_clinic_contacts',
      'read_health_plan',
      'read_quick_scheduling',
    ],
  },
  {
    icon: 'user',
    label: 'Perfil',
    routeName: 'profile',
    permission: ['read_profile'],
  },
  {
    icon: 'clinic-medical',
    label: 'Clínica',
    routeName: 'clinic',
    permission: ['read_clinic'],
  },
  {
    icon: 'coins',
    label: 'Financeiro',
    routeName: 'financial',
    permission: ['read_financial'],
  },
  {
    icon: 'file-excel',
    label: 'Relatórios',
    routeName: 'reports',
    permission: 'read_reports',
  },
];
