import Clear from '@/layouts/Clear.vue';

export default {
  path: '/medical-record-models',
  component: Clear,
  children: [
    {
      path: '',
      name: 'medical-record-models',
      component: () =>
        import('@/views/MedicalRecordModels/MedicalRecordModels.vue'),
    },
    {
      path: 'create',
      name: 'medical-record-models.create',
      component: () =>
        import('@/views/MedicalRecordModels/MedicalRecordModel.vue'),
    },
    {
      path: ':id',
      name: 'medical-record-models.edit',
      component: () =>
        import('@/views/MedicalRecordModels/MedicalRecordModel.vue'),
    },
  ],
};
