import Api from './api.service';

const ENDPOINT = 'dashboard';

export default {
  getScheduleListValues(params = {}) {
    return Api.get(`schedules/total-value`, { params });
  },

  getSchedulesInfo(params = {}) {
    return Api.get(`${ENDPOINT}/schedules`, { params });
  },

  getPatientsTableInfo(params = {}) {
    return Api.get(`${ENDPOINT}/patients-table-info`, { params });
  },
  getPatientsChartsInfo(params = {}) {
    return Api.get(`${ENDPOINT}/patients-charts-info`, { params });
  },
};
