<template>
  <section>
    <app-header icon="clipboard" title="Agenda" />
    <div class="flex_schedule">
      <FirstContainer
        ref="firstContainerRef"
        :selectedDate="currentDate"
        @onSelectDate="(date) => onSelectDate(date)"
        @onSelectDoctor="(doctorId) => (selectedDoctorId = doctorId)"
        @onSelectLocation="(locationId) => (selectedLocationId = locationId)"
        @onSelectHealthPlan="
          (healthPlanId) => (selectedHealthPlanId = healthPlanId)
        "
        @onSelectProcedure="(procedureId) => (selectedProcedureId = procedureId)"
      />

      <SecondContainer
        ref="secondContainerRef"
        :date="selectedDate.date"
        :events="selectedDate.events"
        :doctorId="selectedDoctorId"
        :locationId="selectedLocationId"
        :healthPlanId="selectedHealthPlanId"
        :procedureId="selectedProcedureId"
        @onChangeCurrentDay="(day) => (currentDate = day)"
        @onNewScheduleClick="(event) => (newEventDetails = event)"
        @selectedEventId="(eventId) => (selectedEventId = eventId)"
      />

      <ThirdContainer
        v-if="showThirdContainer"
        :doctorId="selectedDoctorId"
        :scheduleId="selectedEventId"
        :newSchedule="newEventDetails"
        :healthPlanId="selectedHealthPlanId"
        :procedureId="selectedProcedureId"
        :addressId="selectedLocationId"
        @close="() => onReloadEvents(false)"
        @reloadEvents="() => onReloadEvents()"
      />
    </div>
  </section>
</template>

<style lang="scss" scoped>
.flex_schedule {
  display: flex;
  gap: 25px;
}
</style>

<script>
import MonthCalendar from '@/components/schedule/MonthCalendar.vue';
import DayCalendar from '@/components/schedule/DayCalendar.vue';
import ScheduleContainer from '@/components/schedule/ScheduleContainer.vue';

export default {
  components: {
    FirstContainer: MonthCalendar,
    SecondContainer: DayCalendar,
    ThirdContainer: ScheduleContainer,
  },
  data: () => ({
    selectedDate: {},
    newEventDetails: null,
    selectedEventId: null,
    selectedDoctorId: null,
    selectedLocationId: null,
    currentDate: new Date(),
    selectedHealthPlanId: null,
    selectedProcedureId: null,
  }),
  watch: {
    selectedLocationId() {
      console.log(this.selectedLocationId);
    },
    currentDate() {
      this.selectedEventId = null;
      this.newEventDetails = null;
    },
  },
  computed: {
    showThirdContainer() {
      return this.selectedEventId || this.newEventDetails;
    },
  },
  methods: {
    onSelectDate(date) {
      this.selectedDate = date;
      this.newEventDetails = null;
      this.selectedEventId = null;
    },
    onReloadEvents(reload = true) {
      this.selectedEventId = null;
      this.newEventDetails = null;

      if (reload) {
        this.$refs.firstContainerRef.loadDoctorEvents();
        this.$refs.secondContainerRef.loadAvailableHours();
      }
    },
  },
};
</script>
