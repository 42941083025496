import Api from './api.service';

export default {
  verifyLastAcceptedTerm: (userId, clinicId = null) => {
    let params = clinicId ? `clinic_id=${clinicId}` : null;

    return Api.get(`terms/${userId}/verify-accepted-term`, { params });
  },

  acceptTerm: (userId, data = {}) =>
    Api.post(`terms/${userId}/accept-term`, data),

  getId: (id) => Api.get(`terms/${id}`),
  getUserTypes: () => Api.get('terms/base-user-types'),
  saveClinicTerm: (data) => Api.post(`terms/clinic`, data),
  get: (params = {}) => Api.get('terms/clinic', { params }),
  getTermByUserType: (params = {}) => Api.get('terms/base-term', { params }),
};
