<template>
  <section class="schedule-view">
    <div class="schedule-view__header">
      <div>
        <h1>{{ schedule.patient.name }}</h1>
        <div style="display: flex; gap: 5px">
          <h2>
            CPF:
            {{ patientDocument }}
          </h2>
          <h2 v-if="patientAge">IDADE: {{ patientAge }} Anos</h2>
        </div>
      </div>

      <div @click="() => $emit('close')">
        <b-icon icon="times"></b-icon>
      </div>
    </div>
    <div class="schedule-view__details">
      <div
        class="schedule-view__details__payment"
        :class="{ confirmed: !canShowPaymentDropOff }"
      >
        <div>
          <h3>{{ schedule.is_online ? schedule.procedure.value_per_online_procedure : schedule.value | currency('BRL') }}</h3>

          <small v-if="!canShowPaymentDropOff">
            Método de pagamento:
            <span>{{
              schedule.external_payment
                ? schedule.payment.name && schedule.payment.name !== '-'
                  ? schedule.payment.name
                  : 'não informado'
                : 'aplicativo'
            }}</span></small
          >
        </div>

        <b-button
          @click="onPaymentDropOffClick"
          v-if="canShowPaymentDropOff"
          icon-right="check-circle"
          type="is-success"
        >
          Confirmar
        </b-button>

        <b-icon v-else type="is-success" icon="check"></b-icon>
      </div>

      <div class="schedule-view__details__status">
        <b-select
          expanded
          v-model="scheduleStatus"
          placeholder="Selecione o status"
          @input="onChangeStatus(scheduleStatus, schedule.status)"
        >
          <option
            v-for="status of availableStatus"
            :key="status.value"
            :value="status.value"
          >
            {{ status.label }}
          </option>
        </b-select>

        <b-button
          v-if="canShowRescheduleButton"
          @click="onRescheduleClick()"
          icon-right="calendar-alt"
          type="is-secondary"
        >
          Reagendar
        </b-button>
      </div>

      <div class="schedule-view__details__infos custom-scroll">
        <div class="schedule-view__details__information">
          <div>
            <b-icon class="icon-1" icon="hospital-user"></b-icon>
            <p><b>atendimento</b>: {{ schedule.is_online ? 'Online' : 'Presencial' }}</p>
          </div>
        </div>

        <div class="schedule-view__details__information">
          <div>
            <b-icon class="icon-2" icon="notes-medical"></b-icon>
            <p>
              <b>procedimento:</b>
              {{ schedule.procedure_id ? schedule.procedure.name : 'Consulta' }}
            </p>
          </div>
        </div>

        <div class="schedule-view__details__information">
          <div>
            <b-icon class="icon-3" icon="id-card"></b-icon>
            <p>
              <b>convênio:</b>
              {{ schedule.health_plan ? schedule.health_plan.name : 'Nenhum' }}
            </p>
          </div>
        </div>

        <div class="schedule-view__details__information">
          <div>
            <b-icon class="icon-4" icon="toggle-on"></b-icon>
            <p>
              <b>encaixe:</b>
              {{ schedule.fit ? 'Sim' : 'Não' }}
            </p>
          </div>
        </div>

        <div class="schedule-view__details__information">
          <div>
            <b-icon class="icon-5" icon="undo"></b-icon>

            <b>retorno:</b>

            <router-link
              v-if="schedule.voucher && schedule.voucher.voucher_type === 3"
              :to="{
                name: 'schedules.view',
                params: { id: schedule.voucher.schedule.id },
              }"
            >
              {{ schedule.voucher.schedule.start | date }}
            </router-link>

            <span v-else>Não</span>
          </div>
        </div>

        <div
          v-if="schedule.observation"
          class="schedule-view__details__information"
        >
          <div>
            <b-icon class="icon-6" icon="info-circle"></b-icon>
            <p><b>observação do atendimento</b>: {{ schedule.observation }}</p>
          </div>
        </div>

        <div
          v-if="schedule.patient_instructions"
          class="schedule-view__details__information"
        >
          <div>
            <b-icon class="icon-7" icon="receipt"></b-icon>
            <p>
              <b>instrução para o paciente</b>:
              {{ schedule.patient_instructions }}
            </p>
          </div>
        </div>
      </div>
      <div class="schedule-view__details__go-to-schedule">
        <router-link
          v-if="canEdit"
          :to="{
            name: 'schedules.view',
            params: { id: schedule.id },
          }"
        >
          <b-button expanded type="is-dark" icon-right="arrow-right">
            Ir para o atendimento
          </b-button>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>

import SchedulesService from '@/services/schedules.service';
import ScheduleCancelConfirmVue from '../../modals/ScheduleCancelConfirm.vue';
import RescheduleVue from '../../modals/Reschedule.vue';
import ScheduleValueVue from '../../modals/ScheduleValue.vue';

import SCHEDULE_STATUS from '@/enums/ScheduleStatusEnum';

import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'schedule-detail',
  props: {
    scheduleId: {
      type: Number,
      default: () => null,
    },
  },
  data: () => ({
    schedule: {},
    availableStatus: [],
    scheduleStatus: null,
    SCHEDULE_STATUS: SCHEDULE_STATUS,
  }),
  watch: {
    scheduleId() {
      this.loadSchedule();
    },
  },
  computed: {
    patientDocument() {
      if (!this.schedule.id) return null;
      return this.schedule.patient.cpf.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/,
        '$1.$2.$3-$4'
      );
    },
    scheduleType() {
      if (!this.schedule.id) return null;
      return this.schedule.doctor_schedule_value.schedule_type === 0
        ? 'Presencial'
        : 'Teleconsulta';
    },
    canShowPaymentDropOff() {
      if (!this.schedule.id) return false;
      return (
        this.schedule.external_payment &&
        !this.schedule.is_external_payment_paid
      );
    },
    canShowConfirmButton() {
      return [SCHEDULE_STATUS.SCHEDULED].includes(this.schedule.status);
    },
    canShowCancelButton() {
      return [
        SCHEDULE_STATUS.SCHEDULED,
        SCHEDULE_STATUS.WAITING,
        SCHEDULE_STATUS.CONFIRMED,
      ].includes(this.schedule.status);
    },
    canShowRescheduleButton() {
      return [
        SCHEDULE_STATUS.SCHEDULED,
        SCHEDULE_STATUS.WAITING,
        SCHEDULE_STATUS.CONFIRMED,
        SCHEDULE_STATUS.NO_SHOW,
      ].includes(this.schedule.status);
    },
    canEdit() {
      return this.permissions.includes('update_appointments');
    },
    patientAge() {
      if (!this.schedule.id) return null;

      return moment().year() - moment(this.schedule.patient.birthday).year();
    },
    ...mapGetters(['permissions']),
  },
  methods: {
    confirmSchedule() {
      this.$emit('isLoading', true);
      SchedulesService.confirmSchedule(this.scheduleId)
        .then(() => {
          this.schedule.status = SCHEDULE_STATUS.CONFIRMED;
        })
        .finally(() => this.$emit('isLoading', false));
    },
    onConfirmScheduleClick(oldStatus) {
      this.$buefy.dialog.confirm({
        hasIcon: true,
        type: 'is-warning',
        cancelText: 'Cancelar',
        confirmText: 'Confirmar',
        title: 'Confirmação de agenda',
        onConfirm: () => this.confirmSchedule(),
        onCancel: () => (this.schedule.status = oldStatus),
        message: 'Tem certeza que deseja <b>confirmar</b> este agendamento?',
      });
    },
    loadStatus() {
      SchedulesService.getStatus().then(({ data }) => {
        this.availableStatus = Object.values(data);
      });
    },
    loadSchedule() {
      this.$emit('isLoading', true);

      SchedulesService.getId(this.scheduleId)
        .then(({ data }) => {
          this.schedule = data;
          this.scheduleStatus = this.schedule.status;
        })
        .catch(() => this.$buefy.snackbar.open('Erro ao consultar consulta'))
        .finally(() => this.$emit('isLoading', false));
    },
    onChangeStatus(status, oldStatus) {
      if (status == SCHEDULE_STATUS.CANCELED) {
        this.onCancelScheduleClick(oldStatus);
      } else if (status === SCHEDULE_STATUS.CONFIRMED) {
        this.onConfirmScheduleClick(oldStatus);
      } else {
        this.$emit('isLoading', true);

        SchedulesService.updateStatus(this.scheduleId, {
          status: status,
        })
          .then(() => {
            this.schedule.status = status;
          })
          .catch(() => {
            this.scheduleStatus = oldStatus;
          })
          .finally(() => this.$emit('isLoading', false));
      }
    },
    onCancelScheduleClick(oldStatus) {
      this.$buefy.modal.open({
        parent: this,
        trapFocus: true,
        hasModalCard: true,
        component: ScheduleCancelConfirmVue,
        props: {
          justifications: this.justifications,
          schedule: this.schedule,
        },
        events: {
          close: (data) => {
            if (data) this.cancelSchedule(data, oldStatus);
            else this.schedule.status = oldStatus;
          },
        },
      });
    },
    cancelSchedule(data, oldStatus) {
      this.$emit('isLoading', true);

      SchedulesService.cancelSchedule(this.scheduleId, { ...data })
        .then(() => {
          this.$emit('reloadEvents');
          this.schedule.status = SCHEDULE_STATUS.CANCELED;
          this.$buefy.snackbar.open('Consulta cancelada com sucesso!');
        })
        .catch(({ response }) => {
          let errorMessage = 'Erro inesperado ao cancelar consulta!';

          if (response.status == 403) {
            const { message } = response.data;
            errorMessage = message.content;
          }

          this.$buefy.snackbar.open({
            type: 'is-danger',
            duration: 6500,
            message: errorMessage,
          });

          this.schedule.status = oldStatus;
        })
        .finally(() => this.$emit('isLoading', false));
    },
    onRescheduleClick() {
      this.$buefy.modal.open({
        parent: this,
        trapFocus: true,
        hasModalCard: true,
        component: RescheduleVue,
        props: {
          scheduleId: this.schedule.id,
          doctorId: this.schedule.doctor_id,
          procedureId: this.schedule.procedure_id,
          scheduleType: this.schedule.doctor_schedule_value.schedule_type,
        },
        events: {
          close: (data) => {
            data && this.$emit('reloadEvents');
          },
        },
      });
    },
    onClickValue() {
      this.$buefy.modal.open({
        parent: this,
        trapFocus: true,
        hasModalCard: true,
        component: ScheduleValueVue,

        props: {
          oldValue: this.schedule.value,
          selectedPaymentTypeId: this.schedule.payment_type_id,
          selectedPaymentDate: this.schedule.paid_at,
          hideSkipButton: true,
        },
        events: {
          close: (data) => {
            if (!data) return;

            if (data.newValue) {
              this.schedule.value = data.newValue;
            }

            if (data.paymentTypeId) {
              this.schedule.payment_type_id = data.paymentTypeId;
            }

            if (data.paymentDate) {
              this.schedule.paid_at = data.paymentDate;
            }

            this.saveValue(this.schedule);
          },
        },
      });
    },
    saveValue(schedule) {
      this.$emit('isLoading', true);

      const params = {
        payment_type_id: schedule.payment_type_id,
        value: schedule.value,
        paid_at: schedule.paid_at,
      };

      SchedulesService.updatePayment(schedule.id, params).then(({ data }) => {
        this.schedule = { ...this.schedule, ...data };
        this.paymentDropOff();
      });
    },
    onPaymentDropOffClick() {
      this.$buefy.dialog.confirm({
        hasIcon: true,
        type: 'is-warning',
        cancelText: 'Cancelar',
        confirmText: 'Confirmar',
        title: 'Confirmação de pagamento',
        onConfirm: () => this.onClickValue(),
        message:
          'Tem certeza que deseja <b>confirmar</b> o pagamento? Essa ação não poderá ser desfeita.',
      });
    },
    paymentDropOff() {
      SchedulesService.paymentDropOff(this.scheduleId)
        .then(() => {
          this.schedule.is_external_payment_paid = true;
        })
        .finally(() => this.$emit('isLoading', false));
    },
  },
  beforeMount() {
    this.loadStatus();
    this.loadSchedule();
  },
};
</script>

<style lang="scss" scoped>
.schedule-view {
  padding: 16px;

  &__header {
    background-color: var(--color-secondary);

    display: flex;
    justify-content: space-between;

    border-radius: 8px 8px 0 0;
    padding: 8px;

    height: 80px;

    h1,
    h2,
    span {
      color: #fff;
    }

    h1 {
      font-size: 1.6rem;
      font-weight: 600;
    }

    h2 {
      font-weight: 500;
      font-size: 0.9rem;
    }

    div:last-child span:hover {
      cursor: pointer;
    }

    margin-bottom: 10px;
  }

  &__details {
    display: flex;
    gap: 10px;
    flex-direction: column;

    &__infos {
      height: 440px;
      overflow-y: auto;
      padding-right: 5px;
    }

    &__payment,
    &__status,
    &__information {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      background-color: rgba(114, 135, 152, 0.1);

      border-radius: 6px;

      min-height: 60px;

      button {
        min-width: 40%;
      }
    }

    &__payment {
      h3 {
        font-size: 1.6rem;
        font-weight: 700;
        color: #728798;
      }

      small {
        font-size: 0.9rem;
        color: #728798;
        span {
          font-weight: 600;
        }
      }
    }

    &__payment.confirmed {
      background-color: rgba(73, 198, 142, 0.2);

      h3 {
        color: #49c68d;
      }
    }

    &__information {
      margin-bottom: 10px;
      div {
        display: flex;
        align-items: center;

        gap: 10px;

        text-transform: capitalize;

        color: #728798;
        padding: 5px;
      }

      //Não foi minha escolha, por mim nem tinha as cores.
      .icon-1 {
        color: #419f6a;
      }
      .icon-2 {
        color: #317fec;
      }
      .icon-3 {
        color: #fb921a;
      }
      .icon-4 {
        color: #b13f22;
      }
      .icon-5 {
        color: #fb5858;
      }
      .icon-6 {
        color: #6616b5;
      }

      .icon-7 {
        color: #cc199c;
      }
    }

    &__information.status {
      .control {
        width: 50%;
      }
    }

    &__go-to-schedule {
      position: absolute;
      bottom: 16px;
      left: 16px;
      right: 16px;
    }
  }
}
</style>
