<template>
  <div class="app-file-picker">
    <input
      type="file"
      ref="fileInput"
      :accept="accept"
      style="display: none"
      @change="onFileSelect"
    />
    <label class="label">
      {{ label }}
      <span v-if="isRequired">*</span>
    </label>

    <div class="input-content">
      <div class="value" :class="{ error: errorMessage }">
        <span>{{
          fileName && fileName.length > 20
            ? fileName.slice(0, 25) + '...'
            : fileName
        }}</span>
        <button
          type="button"
          class="remove-btn"
          @click="deleteFile"
          v-if="fileName && !disabled"
        >
          <b-icon size="is-small" icon="times"></b-icon>
        </button>
      </div>
      <button
        type="button"
        @click="onClick"
        :class="disabled ? 'disabled' : ''"
      >
        <b-icon size="is-small" icon="upload"></b-icon>
      </button>
    </div>
    <p v-if="errorMessage" class="input-error">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import FileMixin from '@/mixins/FileMixin';
import FileConstants from '@/constants/file.constants';

export default {
  name: 'app-file-picker',
  mixins: [FileMixin],
  model: {
    prop: 'model',
    event: 'input',
  },
  props: {
    model: null,
    accept: {
      type: String,
    },
    label: {
      type: String,
    },
    required: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    onlyFile: {
      type: Boolean,
      default: () => false,
    },
    checkSize: {
      type: Boolean,
      default: () => false,
    },
    maxSize: {
      type: Number,
      default: () => FileConstants.MAX_FILE_SIZE,
    },
    checkDimension: {
      type: Boolean,
      default: () => false,
    },
    maxDimension: {
      type: Object,
      default: () => null,
    },
  },
  data: () => {
    return {
      fileName: null,
      fileUrl: null,
    };
  },
  methods: {
    onClick() {
      !this.disabled && this.$refs.fileInput.click();
    },

    async onFileSelect(event) {
      const [file] = event.target.files;
      let url = null;

      if (this.checkDimension) {
        const correctDimensions = await this.checkMaxDimension(
          file,
          this.maxDimension.width,
          this.maxDimension.height
        );

        if (!correctDimensions) {
          this.$buefy.snackbar.open({
            message: `Por favor, selecione um arquivo com o a dimensão de ${this.maxDimension.width}x${this.maxDimension.height}px`,
            type: 'is-danger',
          });

          this.$refs.fileInput.value = null;

          return;
        }
      }

      if (this.checkSize) {
        const correctSize = await this.checkMaxSize(file, this.maxSize);

        if (!correctSize) {
          this.$buefy.snackbar.open({
            message: `Por favor, selecione um arquivo com menos de ${Math.floor(
              this.maxSize / 1024
            )}mb`,
            type: 'is-danger',
          });

          this.$refs.fileInput.value = null;

          return;
        }
      }

      if (this.onlyFile) url = file;
      else url = await this.fileToBase64(file);

      this.$refs.fileInput.value = null;
      this.fileName = file.name;

      this.updateModel(url);

      this.$emit('fileSelected', file);
    },
    deleteFile() {
      this.fileName = null;
      this.$emit('fileDeleted');
    },
    updateModel(value) {
      this.$emit('input', value);
    },
  },
  computed: {
    isRequired() {
      return this.required === '' || this.required;
    },
    errorMessage() {
      if (!this.errors || !this.errors.length) return null;
      return this.errors[0];
    },
  },
  mounted() {
    this.fileName = this.model;
  },
};
</script>

<style lang="scss" scoped>
.app-file-picker {
  margin: 2px 0;

  label {
    display: block;
    font-size: 16px;
    margin-bottom: 0.5em;

    > span {
      color: var(--color-danger);
      font-size: 0.9em;
      position: relative;
      top: -3px;
      left: -2px;
    }
  }

  .input-content {
    display: flex;
    align-content: center;

    .value {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 0 75%;
      border: 1px solid #ddd;
      outline: 0;
      padding: 8px;
      border-radius: 4px 0 0 4px;
      height: 38px;
      font-size: 1em;
      color: #424242;

      &.error {
        border-color: #dc3545;
      }

      .remove-btn {
        margin-top: 5px;
        color: var(--color-danger);
        border: none;
        background: transparent;
        outline: 0;
        font-size: 1.1em;
        cursor: pointer;
        transition: background 0.2s ease-in-out;
      }
    }

    button:not(.remove-btn) {
      flex: 0 25%;
      border: none;
      background: var(--color-primary);
      color: #fff;
      border-radius: 0 8px 8px 0;
      outline: 0;
      transition: background 0.2s ease-in-out;
      cursor: pointer;

      &:hover {
        background: var(--color-primary);
      }

      .icon {
        font-size: 1.3em;
        margin-right: 4px;
      }
    }

    button:not(.remove-btn).disabled {
      background: gray;
      cursor: not-allowed;
    }
  }

  .input-error {
    color: #dc3545;
    display: block;
    font-size: 0.75rem;
    margin-top: 0.25rem;
  }
}
</style>
