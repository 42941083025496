<template>
  <section>
    <div v-if="showHeader">
      <app-header :icon="pageIcon" :title="pageTitle" />
      <br />
    </div>

    <b-tabs v-model="activeTab" type="is-boxed">
      <b-tab-item
        v-for="(tab, idx) of availableTabs"
        :key="idx"
        :icon="tab.icon"
        :label="tab.label"
      >
        <component :is="tab.component" v-if="activeTab === idx" />
      </b-tab-item>
    </b-tabs>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'routing-tabs',
  props: {
    pageTitle: String,
    pageIcon: String,
    storageKey: String,
    tabs: {
      type: Array,
      default: () => [],
    },
    showHeader: {
      type: Boolean,
      default: () => true,
    },
  },
  computed: {
    availableTabs() {
      return this.tabs.filter(
        (tab) => !tab.permission || this.permissions.includes(tab.permission)
      );
    },
    ...mapGetters(['permissions']),
  },
  data: () => ({
    activeTab: 0,
  }),
  mounted() {
    this.activeTab = localStorage.getItem(this.storageKey)
      ? parseInt(localStorage.getItem(this.storageKey))
      : 0;
  },
  beforeDestroy() {
    localStorage.setItem(this.storageKey, this.activeTab);
  },
};
</script>
