import Vue from 'vue';

import AppInput from '@/components/inputs/AppInput.vue';
import AppEditor from '@/components/inputs/AppEditor.vue';
import AppHeader from '@/components/AppHeader.vue';
import NoContent from '@/components/NoContent.vue';
import AppFilePicker from '@/components/inputs/AppFilePicker.vue';
import PrescriptionMemed from '@/components/memed/PrescriptionMemed.vue';
import ScheduleStatus from '@/components/ScheduleStatus.vue';
import RoutingTabs from '@/components/RoutingTabs.vue';

Vue.component('app-input', AppInput);
Vue.component('app-editor', AppEditor);
Vue.component('app-header', AppHeader);
Vue.component('no-content', NoContent);
Vue.component('app-file-picker', AppFilePicker);
Vue.component('prescription-memed', PrescriptionMemed);
Vue.component('schedule-status', ScheduleStatus);
Vue.component('routing-tabs', RoutingTabs);
