import Clear from '@/layouts/Clear.vue';

export default {
  path: '/custom-app-infos',
  component: Clear,
  children: [
    {
      path: '',
      name: 'custom-app-infos',
      component: () => import('@/views/CustomAppInfos/InfosList.vue'),
    },
    {
      path: ':id?',
      name: 'custom-app-infos.form',
      component: () => import('@/views/CustomAppInfos/InfoForm.vue'),
    },
  ],
};
