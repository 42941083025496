var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card_schedule day-calendar__day-schedule",attrs:{"id":"daySchedule"}},[_c('b-loading',{attrs:{"is-full-page":false,"active":_vm.isLoading},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('div',[_c('div',{staticClass:"day-calendar__day-calendar-header",attrs:{"id":"dayCalendarHeader"}},[_c('b-button',{staticClass:"day-calendar__arrow-day-calendar",on:{"click":function($event){return _vm.changeCurrentDayDate(false)}}},[_c('b-icon',{attrs:{"icon":"chevron-left"}})],1),_c('h1',{staticClass:"day-calendar__title-day-calendar-header"},[_vm._v(" "+_vm._s(_vm.getDayCalendarHeaderTitle)+" ")]),_c('b-button',{staticClass:"day-calendar__arrow-day-calendar",on:{"click":function($event){return _vm.changeCurrentDayDate()}}},[_c('b-icon',{staticClass:"day-calendar__arrow-day-calendar",attrs:{"icon":"chevron-right"}})],1)],1)]),_c('div',{staticClass:"day-calendar__table_calendar custom-scroll",attrs:{"id":"table_calendar"}},[_c('table',{staticClass:"day-calendar__scrollable_table"},_vm._l((_vm.getAvailableEvents),function(event,idx){return _c('tr',{key:idx},[_c('td',{staticStyle:{"padding-top":"10px","vertical-align":"middle"}},[_vm._v(" "+_vm._s(_vm._f("date")(event.start,'HH:mm'))+" ")]),_c('td',{staticStyle:{"width":"100%"}},[(event.title)?_c('div',{staticClass:"day-calendar_schedule-info-display"},[_c('div',{staticClass:"day-calendar__table_item",staticStyle:{"cursor":"pointer"},style:({
                backgroundColor:
                  _vm.selectedEventId === event.id
                    ? 'rgba(80,163,2152,0.2)'
                    : 'rgba(114,135,152,0.1)',
                color:
                  _vm.selectedEventId === event.id
                    ? 'rgba(80,163,212)'
                    : 'rgba(114,135,152)',
              }),on:{"click":function () { return _vm.onEventClick(event.id); }}},[_c('div',[_vm._v(" "+_vm._s(event.fit ? '(Encaixe)' : null)+" "+_vm._s(event.title)+" ")]),_c('div',{staticClass:"day-calendar__appointment_icons"},[_c('b-icon',{staticClass:"mr-2",style:({
                    color: event.type ? '#d0630a' : '#53576b',
                  }),attrs:{"icon":event.type ? 'wifi' : 'map-marker-alt'}}),_c('b-icon',{style:({
                    color: event.self_pay ? '#5d917d' : '#e6546b',
                  }),attrs:{"icon":event.self_pay ? 'wallet' : 'id-card'}})],1)])]):_c('div',[_c('div',{staticClass:"day-calendar__new_appointment_display"},[_c('div',[(event.available)?_c('b-button',{staticClass:"day-calendar__button-icon",on:{"click":function($event){return _vm.onNewScheduleClick(event)}}},[_c('b-icon',{staticClass:"day-calendar__new_appointment_icon",attrs:{"icon":"calendar-plus","size":"is-large"}})],1):_vm._e()],1),_c('div',{staticClass:"day-calendar__appointment-types-icons"},[([1, 2].includes(event.type))?_c('b-icon',{staticStyle:{"color":"#d0630a"},attrs:{"icon":"wifi"}}):_vm._e(),([0, 2].includes(event.type))?_c('b-icon',{staticClass:"day-calendar__new_appointment_icon_details",staticStyle:{"color":"#53576b"},attrs:{"icon":"map-marker-alt"}}):_vm._e()],1)])])])])}),0),(!_vm.hasAnyAvailableHour)?_c('div',{staticClass:"no-schedule-available"},[(!_vm.isLoading)?_c('p',{staticClass:"F-30-7-2"},[_vm._v(" Nenhum horário disponível para esta data. ")]):_vm._e()]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }