import Clear from '@/layouts/Clear.vue';

export default {
  path: '/users',
  component: Clear,
  children: [
    {
      path: '',
      name: 'users',
      component: () => import('@/views/Users.vue'),
    },
  ],
};
