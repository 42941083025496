<template>
  <div class="schedule-status">
    <div class="schedule-status__content">
      <div v-if="schedule.rescheduled_from" class="is-primary">
        Remarcada de:
        {{ schedule.rescheduled_from | date('DD/MM/YYYY [às] HH:mm') }}
      </div>

      <div
        v-if="schedule.canceled_at && !schedule.rescheduled_to"
        class="is-danger"
      >
        Consulta cancelada em:
        {{ schedule.canceled_at | date('DD/MM/YYYY [às] HH:mm') }}
      </div>

      <div v-if="schedule.canceled_at" class="is-dark">
        Cancelada pelo(a):
        {{ schedule.canceled_by_user }}
      </div>

      <div
        v-if="schedule.canceled_at && schedule.justification_text"
        class="is-dark"
      >
        Motivo do cancelamento:
        {{ schedule.justification_text }}
      </div>

      <div class="is-secondary">
        Criada em:
        {{ schedule.created_at | date('DD/MM/YYYY [às] HH:mm') }}
      </div>

      <div class="is-secondary">
        Agendada por: {{ schedule.origin_request_data.label }}
        {{
          schedule.created_by_user && schedule.created_by_user.name
            ? `(${schedule.created_by_user.name})`
            : ``
        }}
      </div>

      <div
        class="is-secondary"
        v-if="schedule.status_changed_at && !schedule.canceled_at"
      >
        Última atualização do status:
        {{
          schedule.user_changed_status
            ? schedule.user_changed_status.name
            : 'Sistema'
        }}
        em
        {{ schedule.status_changed_at | date('DD/MM/YYYY [às] HH:mm') }}
      </div>

      <div
        v-if="
          schedule.active_vouchers != null &&
          schedule.status != SCHEDULE_STATUS.RESCHEDULED
        "
        class="is-info"
      >
        Voucher de retorno:
        {{
          schedule.active_vouchers.invalid && schedule.rescheduled == null
            ? 'Recusado'
            : schedule.active_vouchers.invalid && schedule.rescheduled
            ? 'Aceito'
            : schedule.active_vouchers.code
        }}
      </div>

      <div v-if="schedule.rescheduled" class="is-primary">
        Remarcada por:
        {{ schedule.rescheduled.by }}
      </div>

      <div class="is-primary" v-if="schedule.rescheduled">
        Remarcada em:
        {{ schedule.updated_at | date('DD/MM/YYYY [às] HH:mm') }}
      </div>

      <div v-if="schedule.rescheduled" class="is-secondary">
        Remarcada para:
        {{ schedule.rescheduled.to | date('DD/MM/YYYY [às] HH:mm') }}
      </div>

      <div class="is-info">
        Pagamento {{ schedule.external_payment ? 'externo' : 'interno' }}
      </div>

      <div class="is-primary" v-if="schedule.paid_at">
        Paga em:
        {{ schedule.paid_at | date('DD/MM/YYYY') }}
      </div>

      <div class="is-info" v-if="schedule.payment_updated_at">
        Última atualização Pgto por:
        {{
          (schedule.user_updated_payment &&
            schedule.user_updated_payment.name) ||
          `Sistema`
        }}
        em {{ schedule.payment_updated_at | date('DD/MM/YYYY [às] HH:mm') }}
      </div>

      <div
        :class="`is-${schedule.payment_status.type}`"
        v-if="schedule.payment_status"
      >
        Status Pgto.: {{ schedule.payment_status.label }}
      </div>

      <!-- <div
        class="is-success clickable"
        @click="onClickValue"
        v-if="
          schedule.external_payment &&
          !schedule.voucher &&
          !schedule.health_plan &&
          !schedule.canceled_at
        "
      >
        Valor: {{ schedule.value | currency }}
        <b-icon icon="edit" size="is-small" class="ml-2" />
      </div> -->

      <div class="is-success" v-if="schedule.health_plan">
        {{ schedule.health_plan.name }}
      </div>

      <!-- <div
        class="is-success"
        v-if="
          (!schedule.external_payment && !schedule.canceled_at) ||
          schedule.voucher
        "
      >
        Valor: {{ schedule.value | currency }}
      </div> -->
    </div>
  </div>
</template>

<script>
import ScheduleValue from '@/modals/ScheduleValue.vue';
import ScheduleService from '@/services/schedules.service';
import SCHEDULE_STATUS from '@/enums/ScheduleStatusEnum';
import USER_TYPES from '@/enums/UserTypesEnum';

export default {
  props: {
    schedule: {
      type: Object,
      default: () => {},
    },
    oldSchedule: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    scheduleType: '',
    SCHEDULE_STATUS: SCHEDULE_STATUS,
    USER_TYPES: USER_TYPES,
    paymentTypeId: null,
    paidAt: null,
  }),
  methods: {
    onClickValue() {
      let currentValue = this.schedule.value;

      this.$buefy.modal.open({
        parent: this,
        trapFocus: true,
        hasModalCard: true,
        component: ScheduleValue,
        props: {
          oldValue: currentValue,
          selectedPaymentTypeId: this.schedule.payment_type_id,
          selectedPaymentDate: this.schedule.paid_at,
          hideSkipButton: true,
        },
        events: {
          close: (data) => {
            if (data) {
              this.paymentTypeId = data.paymentTypeId;
              this.paidAt = data.paymentDate;

              if (data.newValue) {
                this.newValue = data.newValue;
              }
              this.saveValue();
            }
          },
        },
      });
    },
    saveValue() {
      const params = {
        payment_type_id: this.paymentTypeId,
        value: this.newValue,
        paid_at: this.paidAt,
      };

      ScheduleService.updatePayment(this.schedule.id, params).then(() => {
        this.schedule.value = this.newValue;
      });
    },
  },
  computed: {
    internalSchedule() {
      return this.schedule.internal == 1 ? 'Sim' : 'Não';
    },
    useVoucher() {
      if (this.schedule.new_schedule) {
        return this.schedule.new_schedule.voucher_id ? 'Aceito' : 'Não aceito';
      }

      return false;
    },
    canceledBy() {
      let canceledBy = false;
      if (this.schedule.canceled_by) {
        switch (this.schedule.canceled_by.type) {
          case USER_TYPES.ADMIN:
            canceledBy = 'Administrador';
            break;
          case USER_TYPES.DOCTOR:
            canceledBy = 'Profissional';
            break;
          case USER_TYPES.SECRETARY:
            canceledBy = 'Secretária';
            break;
          case USER_TYPES.PATIENT:
            canceledBy = 'Paciente';
            break;
          case USER_TYPES.CLINIC:
            canceledBy = 'Clínica';
            break;
          default:
            canceledBy = '--';
        }
      }
      return canceledBy;
    },
  },
  mounted() {
    this.scheduleType =
      this.schedule.doctor_schedule_value.schedule_type == 1
        ? 'Teleconsulta'
        : 'Presencial';
  },
};
</script>

<style lang="scss" scoped>
@import './../styles/buefy';

.schedule-status {
  &__content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;

    div {
      display: inline-flex;
      align-items: center;
      border-radius: 12px;
      width: fit-content;
      padding: 1px 10px;
      font-size: 14px;
      color: #fff;
    }

    div.is-danger {
      background-color: $danger;
    }

    div.is-dark {
      background-color: $dark;
    }

    div.is-primary {
      background-color: $primary;
    }

    div.is-secondary {
      background-color: $secondary;
    }

    div.is-info {
      background-color: $info;
    }

    div.is-success {
      background-color: $success;
    }

    div.is-warning {
      background-color: $warning;
    }

    div.clickable {
      cursor: pointer;
    }
  }
}
</style>
