<template>
  <b-field
    v-bind="$attrs"
    :label="label"
    :type="inputType"
    :message="errorMessage"
    :class="{ required: isRequired }"
  >
    <template #label v-if="tooltipLabel">
      <div class="custom-label">
        {{ label }} &nbsp;
        <b-tooltip multilined :label="tooltipLabel" :position="tooltipPosition">
          <b-icon
            size="is-small"
            type="is-primary"
            icon="question-circle"
          ></b-icon>
        </b-tooltip>
      </div>
    </template>
    <b-input
      :min="min"
      :icon="icon"
      :type="type"
      :step="step"
      v-bind="$attrs"
      :loading="loading"
      v-cleave="mask || {}"
      v-model="innerValue"
      :required="isRequired"
      :maxlength="maxLength"
      :has-counter="hasCounter"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :password-reveal="type === 'password'"
      @icon-right-click="iconRightClickMethod ? iconRightClickMethod() : null"
    ></b-input>
  </b-field>
</template>

<script>
export default {
  props: {
    icon: String,
    label: String,
    tooltipLabel: String,
    type: String,
    min: String,
    step: String,
    placeholder: String,
    errors: Array,
    loading: Boolean,
    mask: Object,
    autocomplete: String,
    isRequired: Boolean,
    value: {
      type: null,
    },
    hasCounter: Boolean,
    iconRightClickMethod: Function,
    maxLength: Number,
    tooltipPosition: {
      type: String,
      default: () => 'is-right',
    },
  },
  data: () => ({
    innerValue: null,
  }),
  computed: {
    inputType() {
      if (this.errors && this.errors.length) return 'is-danger';
      return null;
    },
    errorMessage() {
      if (!this.errors || !this.errors.length) return null;
      return this.errors[0];
    },
  },
  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
    },
    mask(newValue) {
      console.log(newValue);
    },
  },
  mounted() {
    this.innerValue = this.value;
  },
};
</script>

<style lang="scss">
.custom-label {
  display: flex;
  span {
    margin-left: 5px;
  }
}

span.icon.is-right {
  margin-right: 10px !important;
}
</style>
