import Clear from '@/layouts/Clear.vue';

export default {
  path: '/reports',
  component: Clear,
  children: [
    {
      path: '',
      name: 'reports',
      component: () => import('@/views/Reports/Reports.vue'),
    },
  ],
};
