<template>
    <div ref="chartContainer" class="echarts-chart" style="width: 100%; height: 400px;"></div>
  </template>
  
  <script>
  import * as echarts from 'echarts';

  export default {
    name: 'BarChart',
    props: {
      xAxisData: {
        type: Array,
        required: true
      },
      seriesData: {
        type: Array,
        required: true
      },
      drilldownData: {
        type: Array,
        required: true
      }
    },
    mounted() {
      this.initChart();
    },
    methods: {
      initChart() {
        const chartDom = this.$refs.chartContainer;
        const myChart = echarts.init(chartDom);
  
        const option = {
          xAxis: {
            data: this.xAxisData
          },
          yAxis: {},
          dataGroupId: '',
          animationDurationUpdate: 500,
          series: {
            type: 'bar',
            id: 'sales',
            data: this.seriesData,
            universalTransition: {
              enabled: true,
              divideShape: 'clone'
            }
          }
        };
  
        myChart.setOption(option);
  
        myChart.on('click', (event) => {
          if (event.data) {
            const subData = this.drilldownData.find((data) => data.dataGroupId === event.data.groupId);
            if (!subData) return;
  
            myChart.setOption({
              xAxis: {
                data: subData.data.map((item) => item[0])
              },
              series: {
                type: 'bar',
                id: 'sales',
                dataGroupId: subData.dataGroupId,
                data: subData.data.map((item) => item[1]),
                universalTransition: {
                  enabled: true,
                  divideShape: 'clone'
                }
              },
              graphic: [
                {
                  type: 'text',
                  left: 50,
                  top: 20,
                  style: {
                    text: 'Voltar',
                    fontSize: 18
                  },
                  onclick: () => {
                    myChart.setOption(option);
                  }
                }
              ]
            });
          }
        });
      }
    }
  };
  </script>
  
  <style scoped>
  .echarts-chart {
    width: 100%;
    height: 400px;
  }
  </style>
  