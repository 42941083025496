import Clear from '@/layouts/Clear.vue';

export default {
  path: '/quick-scheduling',
  component: Clear,
  children: [
    {
      path: '',
      name: 'quick-scheduling',
      component: () =>
        import('@/views/QuickScheduling/QuickSchedulingList.vue'),
    },
    {
      path: ':id?',
      name: 'quick-scheduling.form',
      component: () => import('@/views/QuickScheduling/QuickScheduling.vue'),
    },
  ],
};
