<template>
  <div class="register-page">
    <div class="register-page__form-container custom-scroll">
      <app-header icon="sign-in-alt" class="app-header" title="Cadastrar-se" />
      <form @submit.prevent="register">
        <div class="row mt-2">
          <div class="col-12">
            <b-field label="Tipo do cadastro" class="required">
              <b-radio
                v-for="(type, idx) in types"
                :native-value="type.value"
                :key="idx"
                v-model="selectedType"
                type="is-primary"
                style="margin-right: 40px; margin-top: 10px"
              >
                {{ type.label }}
              </b-radio>
            </b-field>
          </div>
        </div>

        <div v-if="selectedType == 1" class="row mt-4">
          <div class="col-lg-4 col-md-12 col-sm-12 mt-2">
            <app-input
              type="text"
              is-required
              label="Nome do responsável"
              v-model="clinic.legal_representative_name"
              :errors="errors.legal_representative_name"
            ></app-input>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-12 mt-2">
            <app-input
              type="text"
              label="Nome da clínica"
              is-required
              v-model="clinic.fantasy_name"
              :errors="errors.fantasy_name"
            ></app-input>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-12 mt-2">
            <app-input
              type="tel"
              label="CPF"
              is-required
              v-model="clinic.cpf"
              :errors="errors.cpf"
              v-mask="'###.###.###-##'"
            ></app-input>
          </div>
        </div>

        <div v-if="selectedType == 2" class="row mt-4">
          <div class="col-lg-4 col-md-12 col-sm-12 mt-2">
            <app-input
              type="tel"
              is-required
              label="CNPJ"
              @input="searchCnpj"
              v-model="clinic.cnpj"
              :errors="errors.cnpj"
              v-mask="'##.###.###/####-##'"
            ></app-input>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-12 mt-2">
            <app-input
              is-required
              type="text"
              label="Nome Fantasia"
              v-model="clinic.fantasy_name"
              :errors="errors.fantasy_name"
            ></app-input>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-12 mt-2">
            <app-input
              is-required
              type="text"
              label="Razão Social"
              v-model="clinic.corporate_name"
              :errors="errors.corporate_name"
            ></app-input>
          </div>
        </div>

        <div v-if="selectedType == 2" class="row mt-4">
          <div class="col-lg-6 col-md-12 col-sm-12 mt-2">
            <app-input
              type="text"
              is-required
              label="Nome do responsável"
              v-model="clinic.legal_representative_name"
              :errors="errors.legal_representative_name"
            ></app-input>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-12 mt-2">
            <app-input
              type="tel"
              label="CPF"
              is-required
              v-model="clinic.cpf"
              :errors="errors.cpf"
              v-mask="'###.###.###-##'"
            ></app-input>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-sm-12 col-md-6 col-lg-6 mt-2">
            <app-input
              type="email"
              is-required
              label="E-mail"
              autocomplete="email"
              v-model="clinic.email"
              :errors="errors.email"
            ></app-input>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 mt-2">
            <app-input
              type="email"
              is-required
              label="Confirme o e-mail"
              autocomplete="email"
              v-model="clinic.email_confirmation"
              :errors="errors.email_confirmation"
            ></app-input>
          </div>
        </div>

        <div class="row mt-2 mb-2">
          <div class="col-sm-12 col-md-6 col-lg-6 mt-2">
            <app-input
              type="tel"
              is-required
              label="Telefone"
              v-model="clinic.phone"
              :errors="errors.phone"
              v-mask="'(##) ####-####'"
            ></app-input>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 mt-2">
            <app-input
              type="tel"
              is-required
              label="Telefone Celular"
              v-mask="'(##) #####-####'"
              :errors="errors.cellphone"
              v-model="clinic.cellphone"
            ></app-input>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-12">
            <b-button
              expanded
              native-type="submit"
              type="is-primary"
              :loading="isLoading"
              :disabled="isLoading"
            >
              Cadastrar
            </b-button>
          </div>
          <div class="col-12 mt-2">
            <router-link :to="{ name: 'login' }">
              <b-button type="is-text" expanded> Já tem cadastro? </b-button>
            </router-link>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ClinicsService from '@/services/clinics.service';
import CnpjService from '@/services/cnpj.service';

const vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

export default {
  components: {},
  data: () => ({
    isLoading: false,
    errors: {},
    clinic: {
      cpf: '',
      cnpj: '',
      phone: '',
      email: '',
      cellphone: '',
      fantasy_name: '',
      corporate_name: '',
      legal_representative_name: '',
    },
    types: [
      { label: 'Pessoa Física', value: 1 },
      { label: 'Pessoa Jurídica', value: 2 },
    ],
    selectedType: 1,
  }),
  watch: {
    'clinic.legal_representative_name'(value) {
      if (this.selectedType == 1) this.clinic.fantasy_name = value;
    },
  },
  methods: {
    register() {
      const data = {
        ...this.clinic,
        cpf: this.clinic.cpf ? this.clinic.cpf.replace(/\D/g, '') : null,
        cnpj: this.clinic.cnpj ? this.clinic.cnpj.replace(/\D/g, '') : null,
        phone: this.clinic.phone ? this.clinic.phone.replace(/\D/g, '') : null,
        cellphone: this.clinic.cellphone
          ? this.clinic.cellphone.replace(/\D/g, '')
          : null,
      };

      this.errors = {};
      this.isLoading = true;

      ClinicsService.store(data)
        .then(({ data }) => {
          this.$buefy.snackbar.open({
            message: data.message,
            duration: 10000,
          });
          this.$router.push({ name: 'login' });
        })
        .catch(({ response }) => {
          const { status, data } = response;

          if (status === 422) {
            this.errors = data;
          } else
            this.$buefy.snackbar.open(
              data.message ||
                'Erro ao tentar realizar o cadastro. Tente novamente mais tarde.'
            );
        })
        .finally(() => (this.isLoading = false));
    },
    searchCnpj(cnpj) {
      if (!cnpj) return;

      cnpj = cnpj.replace(/\D/g, '');
      if (cnpj.length < 8) return;

      CnpjService.searchCnpj(cnpj).then(({ data }) => {
        const { corporate_name, fantasy_name } = data;

        this.clinic.corporate_name = corporate_name;
        this.clinic.fantasy_name = fantasy_name;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdownOnError {
  border-color: #dc3545;
}

.register-page {
  height: 100%;
}

.doctor-photo {
  width: 130px;

  img {
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  .select-button {
    width: 35px;
    height: 35px;

    border: 2px solid #fff;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    background: var(--color-primary);

    position: relative;
    top: -45px;
    left: 95px;

    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  .register-page {
    display: flex;
    align-items: center;
    justify-content: center;

    height: calc(var(--vh, 1vh) * 100);

    &__form-container {
      width: 90vw;
      height: 85vh;
      overflow: auto;

      padding: 5%;
    }
  }
}

@media screen and (min-width: 760px) {
  .register-page {
    &__form-container {
      height: 95vh;
    }
  }
}

@media screen and (min-width: 1024px) {
  .register-page {
    padding: 10%;
    overflow: hidden;

    &__form-container {
      .app-header {
        margin-top: -80px;
        margin-bottom: 50px;
      }

      height: 70vh;
      width: 80vw;
    }
  }
}

@media screen and (max-width: 1400px) {
  .register-page {
    &__form-container {
      height: 100vh;
    }
  }
}
</style>
