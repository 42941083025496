import Api from './api.service';

export default {
  get: (params = {}) => Api.get('procedures',{params}),
  getId: (id) => Api.get(`procedures/${id}`),
  store: (procedure) => Api.post('procedures', procedure),
  update: (id, data) => Api.put(`procedures/${id}`, data),
  destroy: (id) => Api.delete(`procedures/${id}`),
  getOriginRequest: () => Api.get('procedures/get-origin-request'),
  updateShowForAttribute: (id, data) => Api.put(`procedures/${id}/update-show-for-attribute`, data),
};
