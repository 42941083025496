import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueMask from 'v-mask';
import VueFontAwesomePicker from 'vfa-picker';


import './config/AppComponentsConfig';
import './config/AppDirectivesConfig';
import './config/AppFiltersConfig';
import './config/FontAwesomeConfig';
import './config/MomentConfig';
import './config/BuefyConfig';

Vue.config.productionTip = false;

Vue.use(VueMask);
Vue.use(VueFontAwesomePicker);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
