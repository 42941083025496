import Clear from '@/layouts/Clear.vue';

export default {
  path: '/health-plans',
  component: Clear,
  children: [
    {
      path: '',
      name: 'health-plans',
      component: () => import('@/views/HealthPlans/HealthPlansList.vue'),
    },
    {
      path: ':id?',
      name: 'health-plans.form',
      component: () => import('@/views/HealthPlans/HealthPlan.vue'),
    },
  ],
};
