export default {
  general_register: [
    '/procedures',
    '/payment-types',
    '/vouchers/create',
    '/contract',
    '/service-locations',
    '/custom-app-infos',
    '/schedules-justification/create',
  ],
  models: [
    '/medical-record-models/create',
    '/prescription-models/create',
    '/contract-models',
  ],
  users: ['/patients/create', '/secretaries/create', '/doctors/'],
};
