<template>
  <div class="month_schedule_view">
    <div class="mt-2">
      <p>Procedimento</p>
      <b-select
        expanded
        placeholder="Selecionar"
        v-model="selectedProcedure"
        :loading="proceduresIsLoading"
        class="month_schedule_view__select-input"
        :disabled="procedures.length === 1"
      >
        <option
          v-for="(procedure, idx) of procedures"
          :key="idx"
          :value="procedure.id"
        >
          {{ procedure.name }}
        </option>
      </b-select>
    </div>

    <div class="mt-2" v-if="selectedProcedure">
      <p>Local de atendimento</p>
      <b-select
        expanded
        placeholder="Selecionar"
        v-model="selectedLocation"
        class="month_schedule_view__select-input"
        :disabled="addresses.length === 1"
      >
        <option
          v-for="(location, idx) in addresses"
          :key="idx"
          :value="location.id"
        >
          {{ location.title }}
        </option>
      </b-select>
    </div>
    <div class="mt-2" v-if="selectedProcedure">
      <p>Convênio</p>
      <b-select
        expanded
        placeholder="Selecionar"
        v-model="selectedHealthPlan"
        :loading="healthPlansIsLoading"
        class="month_schedule_view__select-input"
        :disabled="health_plans.length === 1"
      >
        <option
          v-for="(health_plan, idx) of health_plans"
          :key="idx"
          :value="health_plan.id"
        >
          {{ health_plan.name }}
        </option>
      </b-select>
    </div>
    <div class="mt-2">
      <p class="is-required">Médico/Prof. da saúde</p>
      <b-select
        expanded
        v-model="selectedDoctor"
        placeholder="Selecionar"
        class="month_schedule_view__select-input"
        :disabled="selectedHealthPlan == null || selectedLocation == null || selectedProcedure == null || doctors.length === 1"
      >
        <option v-for="(doctor, idx) of doctors" :key="idx" :value="doctor.id">
          {{ doctor.name }}
        </option>
      </b-select>
    </div>
    <div class="mt-4">
      <b-datepicker
        inline
        :events="events"
        v-model="currentDate"
        @input="onSelectDate"
        class="month_schedule_view__datepicker"
        :disabled="!selectedDoctor || !selectedLocation"
      >
        <b-loading v-model="isLoading" :is-full-page="false" />

        <template #header>
          <div class="month_schedule_view__calendar-header">
            <b-button @click="changeCurrentDate(false)">
              <b-icon
                class="month_schedule_view__arrow-calendar"
                icon="chevron-left"
              />
            </b-button>

            <p class="F-20-7 month_schedule_view__title-calendar">
              {{ getCalendarHeaderTitle }}
            </p>
            <b-button @click="changeCurrentDate()">
              <b-icon
                class="month_schedule_view__arrow-calendar"
                icon="chevron-right"
              />
            </b-button>
          </div>
        </template>
      </b-datepicker>
    </div>

    <div class="month_schedule_view__icon-labels">
      <div>
        <p class="F-20-4 pb-5 pt-5">
          <b-icon
            icon="id-card"
            class="month_schedule_view__schedule-icons-health-plan"
            size="35"
          />Convênio
        </p>
        <p class="F-20-4">
          <b-icon
            icon="wallet"
            class="month_schedule_view__schedule-icons-wallet"
            size="35"
          />
          Particular
        </p>
      </div>

      <div>
        <p class="F-20-4 pb-5 pt-5">
          <b-icon
            icon="map-marker-alt"
            class="month_schedule_view__schedule-icons-map-marker"
            size="35"
          />Presencial
        </p>
        <p class="F-20-4">
          <b-icon
            icon="wifi"
            class="month_schedule_view__schedule-icons-wifi"
            size="35"
          />
          Teleconsulta
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import USER_TYPE_ENUM from '@/enums/UserTypesEnum';
import SCHEDULES_STATUS_ENUM from '@/enums/ScheduleStatusEnum';
import ProceduresService from '@/services/procedures.service';
import clinicsService from '@/services/clinics.service';
import healthPlansService from '@/services/health-plans.service';
import schedulesService from '@/services/schedules.service';
import serviceLocationsService from '@/services/service-locations.service';

export default {
  name: 'month_calendar',
  props: { selectedDate: { type: Date, default: () => new Date() } },
  data() {
    return {
      events: [],
      doctors: [],
      health_plans: [],
      availableLocations: [],
      isLoading: false,
      currentDate: null,
      selectedDoctor: null,
      selectedHealthPlan: null,
      selectedLocation: null,
      healthPlansIsLoading: false,
      USER_TYPE_ENUM: USER_TYPE_ENUM,
      availableLocationsIsLoading: false,
      selectedScheduleType: null,
      specialities: [],
      addresses: [],
      availableScheduleType: [
        { id: 1, title: 'Presencial' },
        { id: 2, title: 'Online' },
      ],
      procedures: [],
      selectedProcedure: null,
      proceduresIsLoading: false,
    };
  },
  watch: {
    selectedProcedure() {

      this.selectedLocation = null;
      this.selectedHealthPlan = null;
      this.selectedDoctor = null;
      this.doctors = [];
      this.addresses = [];
      this.health_plans = [];

      this.loadHealthPlans();
      this.loadAddresses();
      this.$emit('onSelectProcedure', this.selectedProcedure);
    },
    selectedDate(newValue) {
      this.currentDate = newValue;
    },
    currentDate() {
      if (!this.selectedDoctor) return;
      this.setSelectedDateEvents();
    },
    selectedDoctor() {
      this.$emit('onSelectDoctor', this.selectedDoctor);
      this.loadDoctorEvents();
      if (this.selectedLocation) {
        this.loadDoctorEvents();
      }
    },
    selectedLocation() {
      if(this.selectedLocation) {
        this.$emit('onSelectLocation', this.selectedLocation);
      }
    },
    selectedHealthPlan() {
      if(this.selectedHealthPlan == 0 || this.selectedHealthPlan) {
        this.doctors = [];
        this.selectedDoctor = null;
        this.loadClinicDoctors();
        this.$emit('onSelectHealthPlan', this.selectedHealthPlan);
      }
    },
    procedures(newVal) {
      if (newVal.length === 1) {
        this.selectedProcedure = newVal[0].id;
        // Emite evento se necessário
        this.$emit('onSelectProcedure', this.selectedProcedure);
      }
    },
    health_plans(newVal) {
      if (newVal.length === 1) {
        this.selectedHealthPlan = newVal[0].id;
        this.$emit('onSelectHealthPlan', this.selectedHealthPlan);
      }
    },
    doctors(newVal) {
      if (newVal.length === 1 && (this.selectedHealthPlan == 0 || this.selectedHealthPlan)) {
        this.selectedDoctor = newVal[0].id;
        this.$emit('onSelectDoctor', this.selectedDoctor);
      }
    },
  },
  computed: {
    getCalendarHeaderTitle() {
      return moment(this.currentDate).format('MMMM YYYY');
    },
    ...mapGetters(['userType', 'clinicId', 'doctorId']),
  },
  methods: {
    loadDoctorEvents() {
    
    this.isLoading = true;
    this.events = [];
    const firstDayOfMonth = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);

    const params = {
      start: firstDayOfMonth.toISOString().split('T')[0],
      end: lastDayOfMonth.toISOString().split('T')[0],
      clinic_id: this.clinicId,
      doctor_id: this.selectedDoctor,
      health_plan_id: this.selectedHealthPlan == 0 ? null : this.selectedHealthPlan,
      address_id: this.selectedLocation == 9999 ? null : this.selectedLocation,
    };

      schedulesService
        .getScheduleList(params)
        .then(({ data }) => {
          this.availableEvents = data.filter(
            (event) =>
              ![
                SCHEDULES_STATUS_ENUM.CANCELED,
                SCHEDULES_STATUS_ENUM.RESCHEDULED,
              ].includes(event.status.value)
          );

          this.availableDates = [
            ...new Set(
              this.availableEvents.map((event) =>
                moment(event.start).format('YYYY-MM-DD')
              )
            ),
          ];

          this.events = this.availableDates.map((date) => {
            let dateEvents = this.availableEvents.filter((event) => {
              const eventDate = moment(event.start).format('YYYY-MM-DD');
              if (eventDate === date) return event;
            });

            dateEvents = dateEvents.map((event) => ({
              id: event.id,
              fit: event.fit,
              type: event.type,
              status: event.status,
              end: new Date(event.end),
              title: event.patient_name,
              start: new Date(event.start),
              self_pay: !event.health_plan_id,
            }));

            return {
              date: moment(date).toDate(),
              events: dateEvents,
            };
          });

          this.setSelectedDateEvents();
        })
        .finally(() => (this.isLoading = false));
    },
    onSelectDate() {},
    loadClinicDoctors() {
      const params = {
        procedure_id: this.selectedProcedure,
        location_id: this.selectedLocation == 9999 ? null : this.selectedLocation,
        health_plan_id: this.selectedHealthPlan,
      };

      clinicsService
        .getClinicDoctorsWithSchedules(this.clinicId, params)
        .then(({ data }) => {
          this.doctors = data.map(({ id, name }) => ({
            id,
            name,
          }));
          if (this.userType !== USER_TYPE_ENUM.DOCTOR) {
            return;
          } else {
            const doctorFound = this.doctors.find(doctor => doctor.id === this.doctorId);

            if (doctorFound) {
              this.selectedDoctor = this.doctorId;
              this.doctors = [doctorFound];
            }
          }
        })
        .catch(() => {
          this.$buefy.snackbar.open({
            message: 'Erro inesperado ao carregar profissionais!',
          });
        });
    },
    loadProcedures() {
      this.proceduresIsLoading = true;

      ProceduresService
        .get()
        .then(({ data }) => {
          this.procedures = data.map(({ id, name, accepts_online_procedure }) => ({
            id,
            name,
            accepts_online_procedure,
          }));
        })
        .catch(() => {
          this.$buefy.snackbar.open({
            message: 'Erro inesperado ao carregar procedimentos!',
          });
        })
        .finally(() => (this.proceduresIsLoading = false));
    },
    loadHealthPlans() {
      this.healthPlansIsLoading = true;

      const params = {
        procedure_id: this.selectedProcedure,
      };
      healthPlansService
        .get(params)
        .then(({ data }) => {
          this.health_plans = data.map(({ id, name }) => ({
            id,
            name,
          }));

          this.health_plans.unshift({
              title: 'Particular',
              id: 0,
              name: 'Particular',
            });
        })
        .catch(() => {
          this.$buefy.snackbar.open({
            message: 'Erro inesperado ao carregar convênios!',
          });
        })
        .finally(() => (this.healthPlansIsLoading = false));
    },
    loadAddresses() {
      this.addressesIsLoading = true;

      const selectedProcedureObj = this.procedures.find(
        procedure => procedure.id === this.selectedProcedure
      );

      const params = {
        procedure_id: this.selectedProcedure,
      };

      serviceLocationsService
        .get(params)
        .then(({ data: clinicLocations }) => {
          const locations = clinicLocations.map((location) => ({
            title: location.title,
            id: location.address_id,  // Use 'id' aqui
            location_id: location.id,
            address: location.address,
          }));

          if (selectedProcedureObj && selectedProcedureObj.accepts_online_procedure) {
            locations.unshift({
              title: 'Online',
              id: 9999,
              location_id: 0,
              address: 'Online',
            });
          }

          this.addresses = locations;

          if (locations.length === 1) {
            this.$nextTick(() => {
              this.selectedLocation = locations[0].id;
            });
          }
        })
        .finally(() => {
          this.addressesIsLoading = false;
        });
    },
    changeCurrentDate(next = true) {
      const newDate = next
        ? moment(this.currentDate).add(1, 'month').toDate()
        : moment(this.currentDate).subtract(1, 'month').toDate();
        
      this.currentDate = newDate;
      this.loadDoctorEvents(); // Se precisar recarregar os eventos do mês
    },
    setSelectedDateEvents() {
      const selectedDate = moment(this.currentDate).format('YYYY-MM-DD');
      const selectedDateEvents = this.events.filter((event) => {
        if (selectedDate === moment(event.date).format('YYYY-MM-DD'))
          return event;
      })[0];

      this.$emit('onSelectDate', {
        date: selectedDate,
        events: selectedDateEvents ? selectedDateEvents.events : [],
      });
    },
  },

  beforeMount() {
    this.loadProcedures();
    const today = new Date();
    this.currentDate = today;
  },
};
</script>

<style lang="scss" scoped>
/*fonts*/
.F-20-4 {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
}
.F-20-7 {
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
}

.month_schedule_view p {
  color: #728798;
}
.month_schedule_view {
  height: inherit;

  /*elements with the same style used in different containers*/
  box-shadow: 0 0.5em 1em -0.125em rgba(0, 0, 0, 0.1),
    0 0px 0 1px rgba(0, 0, 0, 0.02);
  background-color: #fff;
  padding: 16px;
  border-radius: 6px;

  &__datepicker::v-deep {
    .dropdown-menu {
      width: 100%;
    }
    .dropdown-content {
      border-radius: 6px !important;
      margin-bottom: 20px;
      margin-top: 20px;
      .dropdown-item {
        .datepicker-header,
        .datepicker-footer {
          border: none;
        }

        .datepicker-footer {
          margin-top: 0;
          padding-top: 0;
        }

        .datepicker-content {
          .datepicker-cell {
            padding: 0.5rem 0.3rem !important;
          }

          .datepicker-cell.is-selected {
            background-color: #50a3d4;
          }

          .datepicker-cell.is-today,
          .datepicker-cell.is-selected {
            border-color: #50a3d4;
          }
        }
      }
    }
  }
  /*used in more than one container*/
  &__schedule-icons-health-plan {
    color: #e6546b !important;
    margin-right: 5px;
  }
  &__schedule-icons-map-marker {
    color: #53576b;
    margin-right: 5px;
  }
  &__schedule-icons-wifi {
    color: #d0630a;
    margin-right: 5px;
  }
  &__schedule-icons-wallet {
    color: #5d917d;
  }

  /*fist container*/
  &__calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      border: none;
    }
  }
  &__arrow-calendar {
    color: #50a3d4;
  }
  &__title-calendar {
    text-transform: capitalize;
  }
  &__icon-labels {
    display: flex;
    justify-content: space-between;
  }
}
</style>
