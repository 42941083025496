<template>
  <div class="card_schedule day-calendar__day-schedule" id="daySchedule">
    <b-loading :is-full-page="false" :active.sync="isLoading" />

    <div>
      <div class="day-calendar__day-calendar-header" id="dayCalendarHeader">
        <b-button
          @click="changeCurrentDayDate(false)"
          class="day-calendar__arrow-day-calendar"
        >
          <b-icon icon="chevron-left" />
        </b-button>
        <h1 class="day-calendar__title-day-calendar-header">
          {{ getDayCalendarHeaderTitle }}
        </h1>
        <b-button
          @click="changeCurrentDayDate()"
          class="day-calendar__arrow-day-calendar"
        >
          <b-icon
            class="day-calendar__arrow-day-calendar"
            icon="chevron-right"
          />
        </b-button>
      </div>
    </div>

    <div class="day-calendar__table_calendar custom-scroll" id="table_calendar">
      <table class="day-calendar__scrollable_table">
        <tr v-for="(event, idx) of getAvailableEvents" :key="idx">
          <td style="padding-top: 10px; vertical-align: middle">
            {{ event.start | date('HH:mm') }}
          </td>
          <td style="width: 100%">
            <div v-if="event.title" class="day-calendar_schedule-info-display">
              <div
                class="day-calendar__table_item"
                style="cursor: pointer"
                :style="{
                  backgroundColor:
                    selectedEventId === event.id
                      ? 'rgba(80,163,2152,0.2)'
                      : 'rgba(114,135,152,0.1)',
                  color:
                    selectedEventId === event.id
                      ? 'rgba(80,163,212)'
                      : 'rgba(114,135,152)',
                }"
                @click="() => onEventClick(event.id)"
              >
                <div>
                  {{ event.fit ? '(Encaixe)' : null }} {{ event.title }}
                </div>
                <div class="day-calendar__appointment_icons">
                  <b-icon
                    class="mr-2"
                    :icon="event.type ? 'wifi' : 'map-marker-alt'"
                    :style="{
                      color: event.type ? '#d0630a' : '#53576b',
                    }"
                  />
                  <b-icon
                    :icon="event.self_pay ? 'wallet' : 'id-card'"
                    :style="{
                      color: event.self_pay ? '#5d917d' : '#e6546b',
                    }"
                  />
                </div>
              </div>
            </div>
            <div v-else>
              <div class="day-calendar__new_appointment_display">
                <div>
                  <b-button
                    v-if="event.available"
                    @click="onNewScheduleClick(event)"
                    class="day-calendar__button-icon"
                    ><b-icon
                      class="day-calendar__new_appointment_icon"
                      icon="calendar-plus"
                      size="is-large"
                    />
                  </b-button>
                </div>
                <div class="day-calendar__appointment-types-icons">
                  <b-icon
                    v-if="[1, 2].includes(event.type)"
                    icon="wifi"
                    style="color: #d0630a"
                  />
                  <b-icon
                    v-if="[0, 2].includes(event.type)"
                    class="day-calendar__new_appointment_icon_details"
                    icon="map-marker-alt"
                    style="color: #53576b"
                  />
                </div>
              </div>
            </div>
          </td>
        </tr>
      </table>
      <div v-if="!hasAnyAvailableHour" class="no-schedule-available">
        <p v-if="!isLoading" class="F-30-7-2">
          Nenhum horário disponível para esta data.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

import SchedulesService from '@/services/schedules.service';

export default {
  name: 'day-calendar',

  props: {
    date: {
      type: String,
      default: () => '',
    },
    events: {
      type: Array,
      default: () => [],
    },
    doctorId: {
      type: Number,
      default: () => null,
    },
    healthPlanId: {
      type: Number,
      default: () => null,
    },
    locationId: {
      type: Number,
      default: () => null,
    },
    procedureId: {
      type: Number,
      default: () => null,
    },
  },
  data: () => ({
    isLoading: false,
    selectedEventId: null,
    currentDate: new Date(),
    availableBothHours: [],
    availableOnlineHours: [],
    availableInPersonHours: [],
  }),
  watch: {
    date(newValue) {
      this.selectedEventId = null;
      this.currentDate = newValue;
      this.loadAvailableHours();
    },
    doctorId() {
      if (this.locationId && this.procedureId && (this.healthPlanId == 0 || this.healthPlanId)) {

        this.loadAvailableHours()
      } else {
        this.availableBothHours = [];
        this.availableOnlineHours = [];
        this.availableInPersonHours = [];
      }
    },
    selectedEventId() {
      this.$emit('selectedEventId', this.selectedEventId);
    },
  },
  computed: {
    hasAnyAvailableHour() {
      return (
        [
          ...this.availableInPersonHours,
          ...this.availableOnlineHours,
          ...this.availableBothHours,
        ].length && this.doctorId
      );
    },
    getDayCalendarHeaderTitle() {
      return moment(this.currentDate).format('DD [de] MMMM [de] YYYY');
    },
    getAvailableEvents() {
      const availableEvents = [
        ...this.events,
        ...this.availableInPersonHours,
        ...this.availableOnlineHours,
        ...this.availableBothHours,
      ];

      availableEvents.sort((last, current) => {
        const lastHour = moment(last.start).format('HH:mm');
        const currentHour = moment(current.start).format('HH:mm');

        if (lastHour < currentHour) return -1;
        else if (lastHour > currentHour) return 1;
        else 0;
      });

      return availableEvents;
    },
    ...mapGetters(['clinicId']),
  },
  methods: {
    onEventClick(eventId) {
      if (this.selectedEventId === eventId) this.selectedEventId = null;
      else this.selectedEventId = eventId;
    },
    onNewScheduleClick(event) {
      this.selectedEventId = null;
      this.$emit('selectedEventId', null);
      this.$emit('onNewScheduleClick', event);
    },
    changeCurrentDayDate(next = true) {
      if (next) {
        this.currentDate = moment(this.currentDate).add(1, 'day').toDate();
      } else {
        this.currentDate = moment(this.currentDate).subtract(1, 'day').toDate();
      }

      this.selectedEventId = null;
      this.$emit('onChangeCurrentDay', this.currentDate);
    },
    loadAvailableHours() {
      this.availableBothHours = [];
      this.availableOnlineHours = [];
      this.availableInPersonHours = [];

      this.isLoading = true;
      
      const params = {
        'filter[doctor_id]': this.doctorId,
        'filter[clinic_id]': this.clinicId,
        'filter[address_id]': this.locationId,
        'filter[health_plan_id]': this.healthPlanId,
        selectedDate: moment(this.currentDate).format('YYYY-MM-DD'),
        'filter[procedure_id]': this.procedureId,
      };

      const promises = [
        SchedulesService.getScheduleEvents({
          ...params,
          appointment_type_id: 0,
        }),
        SchedulesService.getScheduleEvents({
          ...params,
          appointment_type_id: 1,
        }),
        SchedulesService.getScheduleEvents({
          ...params,
          appointment_type_id: 2,
        }),
      ];

      Promise.all(promises)
        .then((data) => {
          const [inPersonHours, onlineHours, bothHours] = data;

          const mapValues = (availableHour) => {
            const startDate = moment(availableHour.start);
            const now = moment();

            return {
              start: startDate,
              id: availableHour.id,
              type: availableHour.type,
              address: availableHour.address,
              available: now.isBefore(startDate),
              standard_time: availableHour.standard_time,
            };
          };

          this.availableBothHours = bothHours.data
            .map(mapValues)
            .filter((hour) => hour.type == 2);
          this.availableOnlineHours = onlineHours.data
            .map(mapValues)
            .filter((hour) => hour.type == 1);
          this.availableInPersonHours = inPersonHours.data
            .map(mapValues)
            .filter((hour) => hour.type == 0);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
/*fonts*/

.F-30-7-2 {
  font-size: 28px;
  font-weight: 700;
  line-height: 40px;
  color: #728798;
}
.day-calendar p {
  color: #728798;
  display: flex;
  align-items: center;
}
.inline {
  display: inline !important;
}
.no-schedule-available {
  display: flex;
  justify-content: center;
  margin-top: 150px;
}
.svg-inline--fa.fa-w-14 {
  width: 2em !important;
}
.day-calendar {
  &__day-schedule {
    position: relative;
    background-color: white;
    padding: 16px;
    box-shadow: 0 0.5em 1em -0.125em rgba(0, 0, 0, 0.1),
      0 0px 0 1px rgba(0, 0, 0, 0);
    height: inherit;
    border-radius: 6px;
  }
  &__schedule-icons {
    color: #ff7677;
    margin-right: 5px;
  }
  &__schedule-icons-health-plan {
    color: #1695e0 !important;
    margin-right: 5px;
  }
  &__schedule-icons-map-marker {
    color: #bc6b0f;
    margin-right: 5px;
  }
  &__schedule-icons-wifi {
    color: #d0630a;
    margin-right: 5px;
  }
  &__schedule-icons-wallet {
    color: #8ca03f;
  }
  &__button-icon {
    border: none !important;
    width: 45px !important;
    height: 45px !important;
  }
  &__button-icon:focus {
    border: none !important;
    box-shadow: none !important;
    background-color: rgb(80, 163, 212, 0.3) !important;
    height: 45px !important;
  }

  &__day-calendar-header {
    background-color: #50a3d4;
    padding: 20px 10px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__arrow-day-calendar {
    color: white;
    border: none;
    background-color: transparent !important;
  }
  &__title-day-calendar-header {
    color: white !important;
    font-size: 1.6rem;
    font-weight: 600;
  }
  &__table_calendar {
    max-height: 600px;
    overflow-y: auto;
  }
  &__scrollable_table {
    width: 100%;
    border-collapse: collapse;
  }
  &__table_calendar table {
    th,
    td {
      padding: 5px 5px;
      border: solid 1px #b7b7b7;
      font-size: 17px;
      color: #728798;
      font-weight: 700;
    }
  }
  &__table_item {
    background-color: rgba(80, 163, 212, 0.25);
    padding: 5px 10px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  &__appointment_icons {
    display: flex;
    align-items: center;
  }

  &__new_appointment_icon {
    color: #50a3d4;
    border: none;
    width: 45px !important;
    height: auto !important;
    display: flex;
    align-items: center;
  }
  &__day-schedule {
    width: 100%;
  }
  &__new_appointment_display {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  &__new_appointment_icon_details {
    margin-right: 5px;
  }
}

.icon-color {
  color: #8ca03f !important;
}
</style>
