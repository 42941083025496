import Api from './api.service';

export default {
  get: (params = {}) => Api.get('patients', { params }),

  getPatients: (term = '', params = {}) =>
    Api.get(`patients/clinic?filter[name_email_or_document]=${term}`, { params }),

  search: (term = '', params = {}) =>
    Api.get(`patients?filter[name_email_or_document]=${term}`, { params }),
  getId: (id) => Api.get(`patients/${id}`),
  store: (data) => Api.post('patients', data),
  update: (id, data) => Api.put(`patients/${id}`, data),
  destroy: (id) => Api.delete(`patients/${id}`),
  import: (file) => {
    const formData = new FormData();
    formData.append('file', file);

    return Api.post('patients/import', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    });
  },
  export: (params = {}) => Api.get('patients/export', { params }),
  paymentOptions: () => Api.get('payment-options'),
  setPaymentOption: (patientId, data = {}) =>
    Api.patch(`patients/${patientId}/set-payment-option`, data),
};
