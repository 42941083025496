import Api from './api.service';

const ENDPOINT = 'clinics';

export default {
  show: (id) => Api.get(`${ENDPOINT}/${id}`),
  store: (data) => Api.post(`${ENDPOINT}`, data),
  delete: (id) => Api.delete(`${ENDPOINT}/${id}`),
  get: (params = {}) => Api.get(`${ENDPOINT}`, { params }),
  update: (id, data) => Api.put(`${ENDPOINT}/${id}`, data),
  updateClinicStatus: (id, data) =>
    Api.patch(`${ENDPOINT}/${id}/update-clinic-status`, data),
  updateClinicAddress: (id, data) =>
    Api.post(`${ENDPOINT}/${id}/update-clinic-address`, data),
  getDoctorAdmin: (id) => Api.get(`${ENDPOINT}/${id}/doctor-admin`),
  loadCompanyTypes: () => Api.get('company-types'),
  saveClinicAdmin: (clinicId, data) =>
    Api.post(`${ENDPOINT}/${clinicId}/clinic-admin`, data),
  saveCompanyMember: (clinicId, data = {}) =>
    Api.post(`${ENDPOINT}/${clinicId}/save-company-member`, data),
  loadCompanyMembers: (clinicId) =>
    Api.get(`${ENDPOINT}/${clinicId}/company-members`),
  deleteCompanyMember: (memberId) =>
    Api.delete(`${ENDPOINT}/${memberId}/delete-company-member`),
  loadLegalRepresentativeTypes: (companyType) =>
    Api.get(`${companyType}/legal-representative-types`),
  getClinicDoctors: (clinicId, params = {}) =>
    Api.get(`${ENDPOINT}/${clinicId}/clinic-doctors`, { params }),
  getClinicDoctorsWithSchedules: (clinicId, params = {}) =>
    Api.get(`${ENDPOINT}/${clinicId}/clinic-doctors-with-schedules`, { params }),
  saveClinicDoctor: (clinicId, data = {}) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(`${key}`, data[key]);
    });

    return Api.post(`${ENDPOINT}/${clinicId}/clinic-doctor`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    });
  },
  getBalanceInfo(clinicId) {
    return Api.get(`${ENDPOINT}/${clinicId}/balance-consult`);
  },
  setupClinicAntecipation(clinicId) {
    return Api.get(`${ENDPOINT}/${clinicId}/setup-clinic-antecipation`);
  },
  getTransactions(params = {}, clinicId) {
    return Api.get(`${ENDPOINT}/${clinicId}/transactions`, { params });
  },
  transferBalance(data, clinicId) {
    return Api.post(`${ENDPOINT}/${clinicId}/request-withdraw`, data);
  },
  simulateByAmount(amount, clinicId) {
    return Api.get(`${ENDPOINT}/${clinicId}/simulate-by-amount`, { amount });
  },
  requestAdvance(simulationId, clinicId) {
    return Api.post(`${ENDPOINT}/${clinicId}/advance-request`, {
      simulation_id: simulationId,
    });
  },
  saveContactInfos: (data) => Api.post('clinic_contact_infos/save', data),
  viewContactInfos: (clinicId) =>
    Api.get('clinic_contact_infos/view', { params: { clinic_id: clinicId } }),
  getCanCreateDoctors: (clinicId) =>
    Api.get(`${ENDPOINT}/${clinicId}/can-create-doctors`),
  saveClinicBankInfos: (clinicId, data) =>
    Api.post(`${ENDPOINT}/banks/${clinicId}/save-bank-info`, data),
  locationsAvailableForDoctors: (clinicId) =>
    Api.get(`${ENDPOINT}/${clinicId}/locations-available-for-doctors`),
  paymentOptions: () => Api.get('payment-options'),
  updateDefaultSettings: (clinicId, data = {}) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      if (data[key]) formData.append(`${key}`, data[key]);
    });

    return Api.post(
      `${ENDPOINT}/${clinicId}/update-default-settings`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/json',
        },
      }
    );
  },
};
