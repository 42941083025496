import Clear from '@/layouts/Clear.vue';

export default {
  path: '/contract',
  component: Clear,
  children: [
    {
      path: '',
      name: 'contract',
      component: () => import('@/views/Contracts/ContractsList.vue'),
    },
    {
      path: ':id?',
      name: 'contract.form',
      component: () => import('@/views/Contracts/Contract.vue'),
    },
  ],
};
