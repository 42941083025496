<template>
  <div class="timetable-view">
    <app-header icon="clock" title="Horários"></app-header>

    <section>
      <div class="card">
        <header class="card-header">
          <div class="card-header-title">
            <div class="caption">
              <div class="caption__item">
                <div class="color apt-0"></div>
                <p>Presencial</p>
              </div>
              <div class="caption__item">
                <div class="color apt-1"></div>
                <p>Teleconsulta</p>
              </div>
              <div class="caption__item">
                <div class="color apt-0 apt-1"></div>
                <p>Ambos</p>
              </div>
            </div>
          </div>
          <div
            v-if="userType === userTypesEnum.DOCTOR"
            class="card-header-buttons"
          >
            <b-tooltip label="Novo Horário">
              <b-button
                v-if="canCreate"
                icon-left="plus"
                type="is-primary"
                @click="newTime()"
                :disabled="!selectedDoctorId"
              >
                Adicionar
              </b-button>
            </b-tooltip>

            <b-tooltip class="ml-2" label="Limpar todos os horários">
              <b-button
                v-if="canDelete"
                :disabled="isEmpty"
                @click="onDeleteAllTimesClick()"
                type="is-dark"
                icon-left="trash"
              >
                Limpar horários
              </b-button>
            </b-tooltip>
          </div>
        </header>
        <div class="card-content">
          <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading>

          <div
            v-if="userType !== userTypesEnum.DOCTOR"
            class="card-content-actions"
          >
            <b-field class="mr-5">
              <b-select
                expanded
                name="medic"
                icon="filter"
                @input="loadTimes"
                v-model="selectedDoctorId"
                placeholder="Selecionar profissional"
              >
                <option
                  v-for="doctor of doctors"
                  :key="doctor.id"
                  :value="doctor.id"
                >
                  {{ doctor.name }}
                </option>
              </b-select>
            </b-field>

            <b-tooltip label="Novo Horário">
              <b-button
                v-if="canCreate"
                icon-left="plus"
                type="is-primary"
                @click="newTime()"
                :disabled="!selectedDoctorId"
              >
                Adicionar
              </b-button>
            </b-tooltip>

            <b-tooltip label="Bloquear horários">
                <b-button
                  v-if="canCreate"
                  icon-left="clock"
                  type="is-warning"
                  :disabled="!selectedDoctorId"
                  @click="blockTime()"
                  class="ml-2"
                >
                  Bloquear horários
                </b-button>
            </b-tooltip>

            <b-tooltip class="ml-2" label="Limpar todos os horários">
              <b-button
                v-if="canDelete"
                :disabled="isEmpty"
                @click="onDeleteAllTimesClick()"
                type="is-dark"
                icon-left="trash"
              >
                Limpar horários
              </b-button>
            </b-tooltip>
          </div>

          <no-content
            v-if="isEmpty"
            class="mt-5"
            text="Nenhum horário cadastrado."
            icon="clock"
          ></no-content>

          <div class="weekdays-container">
            <div
              v-for="(day, weekday) in days"
              :key="`d${weekday}`"
              class="weekday"
            >
              <div class="weekday__title">
                <p>{{ weekdays[weekday] | titleCase }}</p>
              </div>
              <div class="weekday__times custom-scroll">
                <ol>
                  <li
                    v-for="(time, idx) in day"
                    :key="`t${idx}`"
                    :class="classTime(time.type)"
                  >
                    {{ time.start_hour | time }} - {{ time.end_hour | time }}
                    {{ time.location_title ? `- ${time.location_title}` : '' }}
                    {{ time.type_of_schedule == 2 ? `Convênio(s)` : 'Particular' }}
                    <br />
                    {{ formatDate(time.date_start, time.date_end) }}
                    <b-button
                      v-if="canDelete"
                      @click="deleteTime(time)"
                      type="is-light"
                      icon-left="times"
                      size="is-small"
                    ></b-button>
                  </li>
                </ol>
              </div>
              <div class="weekday__clear">
                <b-button
                  v-if="canDelete"
                  @click="onClearClick(weekday)"
                  :disabled="!day.length"
                  type="is-text"
                  size="is-small"
                >
                  Limpar
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SchedulesService from '@/services/schedules.service';
import ClinicsService from '@/services/clinics.service';

import TimeSchedule from '@/modals/TimeSchedule.vue';
import * as moment from 'moment';
import { mapGetters } from 'vuex';
import UserTypesEnum from '../enums/UserTypesEnum';

export default {
  filters: {
    time(value) {
      const [hour, min] = value.split(':');
      return `${hour}:${min}`;
    },
  },
  data: () => ({
    isLoading: false,
    days: [],
    weekdays: moment.weekdays(),
    standard_times: [20, 30, 45, 60],
    doctors: [],
    selectedDoctorId: null,
    userTypesEnum: UserTypesEnum,
  }),
  computed: {
    isEmpty() {
      return Object.keys(this.days).reduce((a, b) => a + b.length, 0) === 0;
    },
    canCreate() {
      const permissions = ['create_timetables', 'create_doctor_timetable'];

      return permissions.some((permission) =>
        this.permissions.includes(permission)
      );
    },
    canDelete() {
      const permissions = ['create_timetables', 'create_doctor_timetable'];

      return permissions.some((permission) =>
        this.permissions.includes(permission)
      );
    },
    ...mapGetters([
      'permissions',
      'doctorType',
      'doctorId',
      'userType',
      'clinicId',
    ]),
  },
  methods: {
    blockTime() {
      let id = this.selectedDoctorId;
      this.$router.push({ name: 'timetable.doctor', params: { id } });
    },
    formatDate(date_start, date_end) {
      let date_result = '';
      date_result = date_start ? moment(date_start).format('DD/MM/YYYY') : '';
      date_result += date_start && date_end ? ' - ' : '';
      date_result += date_end ? moment(date_end).format('DD/MM/YYYY') : '';
      return date_result;
    },
    loadTimes() {
      this.isLoading = true;

      SchedulesService.getAvailabilities(this.selectedDoctorId)
        .then((times) => (this.days = times))
        .finally(() => (this.isLoading = false));
    },
    newTime() {
      this.$buefy.modal.open({
        parent: this,
        component: TimeSchedule,
        hasModalCard: true,
        trapFocus: true,
        width: 1200,
        props: {
          doctorId: this.selectedDoctorId,
        },
        events: {
          close: (data) => data && this.loadTimes(),
        },
      });
    },

    onDeleteAllTimesClick() {
      this.$buefy.dialog.confirm({
        title: 'Excluindo horários',
        message:
          'Tem certeza que deseja <b>excluir</b> todos seus horários? Essa ação não pode ser desfeita.',
        cancelText: 'Cancelar',
        confirmText: 'Excluir todos os horários',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteAllTimes(),
      });
    },
    deleteAllTimes() {
      const promises = Object.keys(this.days).map((day) =>
        this.days[day].map((scd) => {
          SchedulesService.deleteAvailability(scd.id);
        })
      );

      Promise.all(promises).then(() => {
        this.days = [];
        this.$buefy.snackbar.open('Horários excluídos com sucesso!');
      });
    },
    deleteTime({ weekday, id }) {
      SchedulesService.deleteAvailability(id).then(() => {
        this.days[weekday] = this.days[weekday].filter(
          (time) => time.id !== id
        );
        this.$buefy.snackbar.open('Horário excluído com sucesso!');
      });
    },
    onClearClick(weekday) {
      this.$buefy.dialog.confirm({
        message: `Deseja realmente excluir todos os horários de ${this.weekdays[weekday]}?`,
        onConfirm: () => this.clearWeekday(weekday),
      });
    },
    clearWeekday(weekday) {
      const promises = this.days[weekday].map((scd) =>
        SchedulesService.deleteAvailability(scd.id)
      );

      Promise.all(promises).then(() => {
        this.days[weekday] = [];
        this.$buefy.snackbar.open('Horários excluídos com sucesso!');
      });
    },
    classTime(appointment_type) {
      return `apt-${appointment_type}`;
    },
    loadDoctors() {
      this.isLoading = true;

      ClinicsService.getClinicDoctors(this.clinicId, {})
        .then(({ data }) => {
          this.doctors = data;

          if (this.userType === UserTypesEnum.CLINIC && this.doctorId) {
            this.selectedDoctorId = this.doctorId;
            this.loadTimes();
          } else {
            this.isLoading = false;
          }
        })
        .catch(() => {
          this.$buefy.snackbar.open({
            message: 'Erro inesperado ao carregar profissionais!',
          });

          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.loadTimes();
  },
  beforeMount() {
    if (this.userType !== UserTypesEnum.DOCTOR) {
      this.loadDoctors();
    } else {
      this.selectedDoctorId = this.doctorId;
    }
  },
};
</script>

<style lang="scss" scoped>
.card-content-actions {
  display: flex;
  justify-content: space-between;

  div:first-child {
    width: 100%;
  }
}
.caption {
  display: flex;
  gap: 10px;
  margin-bottom: 8px !important;

  &__item {
    display: flex;
    align-items: center;

    .color {
      height: 12px;
      width: 12px;
      border-radius: 50%;
      margin-right: 8px;
    }

    p {
      font-size: 1rem;
    }
  }
}

.apt-0 {
  background: var(--color-primary);
  border-color: var(--color-primary);
}

.apt-1 {
  background: var(--color-secondary);
  border-color: var(--color-secondary);
}

.apt-0.apt-1 {
  background: var(--color-dark);
  border-color: var(--color-dark);
}

.apt-2 {
  background: var(--color-dark);
  border-color: var(--color-dark);
}

.weekdays-container {
  margin-top: 40px;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .weekday {
    min-width: 350px;

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    &:not(:last-child) {
      margin-right: 12px;
    }

    &__title {
      text-align: center;
      font-weight: bold;
      margin-bottom: 6px;
    }

    &__times {
      background: #f9f9f9;
      padding: 12px 16px;
      max-height: 200px;
      overflow-y: scroll;

      ol {
        padding: 0;
        list-style-type: none;

        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #fff;
          padding-left: 6px;
          margin-bottom: 6px;
          border: 1px solid;
          border-radius: 4px;

          button {
            background: transparent;
            color: #fff;

            &:hover {
              background: rgba(0, 0, 0, 0.1);
            }
          }
        }
      }
    }

    &__clear {
      text-align: right;
    }
  }
}
</style>
