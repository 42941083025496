import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import DashboardLayout from '@/layouts/Dashboard.vue';
import Login from '@/views/Login.vue';
import Register from '@/views/Register.vue';
import Home from '@/views/Home.vue';
import Financial from '@/views/Financial.vue';
import Schedule from '@/views/Schedule.vue';
import Timetable from '@/views/Timetable.vue';
// import MedicalRecordModel from '@/views/MedicalRecordModel.vue';
import SecretaryProfile from '@/views/SecretaryProfile.vue';
import Dashboard from '@/views/Dashboard.vue';
import SCHEDULES_ROUTES from '@/router/schedules.routes';
import VOUCHERS_ROUTES from '@/router/vouchers.routes';
import PRESCRIPTION_MODELS_ROUTES from '@/router/prescription-models.routes';
import SCHEDULES_JUSTIFICATIONS from '@/router/schedules-justifications.routes';
import PATIENTS_ROUTES from '@/router/patients.routes';
import PROFILE_ROUTES from '@/router/profile.routes';
import SECRETARIES_ROUTES from '@/router/secretaries.routes';
import PROCEDURES_ROUTES from '@/router/procedures.routes';
import PAYMENT_TYPES_ROUTES from '@/router/payment-types.routes';
import DIGITAL_CERTIFICATE from '@/router/digital-certificate.routes';
import CUSTOM_APP_INFOS from '@/router/custom-app-infos.routes';
import CLINIC_ROUTES from '@/router/clinic.routes';
import CONTACT_US from '@/router/contact-us.routes';
import DOCTORS from '@/router/doctors.routes';
import SERVICE_LOCATIONS from '@/router/service-locations.routes';
import DASHBOARD from './dashboard.routes';
import CONTACT_INFOS from './clinic-contact-infos.routes';
import TIMETABLE_ROUTES from '@/router/timetable.routes';
import REPORT_ROUTES from './reports.routes';
import MEDICAL_RECORD_MODELS from './medical-record-models.routes';
import CONTRACT_MODEL from './contract-model.routes';
import CONTRACT from './contract.routes';
import MODELS from './models.routes';
import USERS from './users.routes';
import GENERAL_REGISTER from './general-register.routes';
import HEALTH_PLANS from './health-plans.routes';
import QUICK_SCHEDULING from './quick-scheduling.routes';
import RECURRENCE_PLANS from './recurrence-plans.routes';

// import TERM_ROUTES from '@/router/term.routes';

Vue.use(VueRouter);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next('/');
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next('/login');
};

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/',
    component: DashboardLayout,
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: '',
        name: 'index',
        component: Home,
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: Dashboard,
      },
      {
        path: 'schedule',
        name: 'schedule',
        component: Schedule,
      },
      {
        path: 'timetable',
        name: 'timetable',
        component: Timetable,
      },
      {
        path: 'secretary-profile',
        name: 'secretary-profile.index',
        component: SecretaryProfile,
      },

      {
        path: 'financial',
        name: 'financial',
        component: Financial,
      },
      DASHBOARD,
      DOCTORS,
      CONTACT_US,
      CLINIC_ROUTES,
      PATIENTS_ROUTES,
      CUSTOM_APP_INFOS,
      SCHEDULES_ROUTES,
      VOUCHERS_ROUTES,
      PROCEDURES_ROUTES,
      SERVICE_LOCATIONS,
      SECRETARIES_ROUTES,
      DIGITAL_CERTIFICATE,
      PRESCRIPTION_MODELS_ROUTES,
      SCHEDULES_JUSTIFICATIONS,
      PROFILE_ROUTES,
      CONTACT_INFOS,
      TIMETABLE_ROUTES,
      PAYMENT_TYPES_ROUTES,
      REPORT_ROUTES,
      MEDICAL_RECORD_MODELS,
      CONTRACT_MODEL,
      CONTRACT,
      MODELS,
      USERS,
      GENERAL_REGISTER,
      HEALTH_PLANS,
      QUICK_SCHEDULING,
      RECURRENCE_PLANS,
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
