import Clear from '@/layouts/Clear.vue';

export default {
  path: '/general-register',
  component: Clear,
  children: [
    {
      path: '',
      name: 'general-register',
      component: () => import('@/views/GeneralRegister.vue'),
    },
  ],
};
