import Clear from '@/layouts/Clear.vue';

export default {
  path: '/clinic',
  component: Clear,
  children: [
    {
      path: '',
      name: 'clinic',
      component: () => import('@/views/Clinic/Clinic.vue'),
    },
  ],
};
