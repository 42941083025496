<template>
  <section class="events-calendar">
    <div class="calendar">
      <div class="calendar__header">
        <div @click="handleMonthChange()" class="calendar__header__button">
          <b-icon
            v-if="canViewPreviousButton"
            icon="caret-left"
            size="is-medium"
            type="is-white"
          ></b-icon>
        </div>

        <div class="calendar__header__view-title">{{ viewTitle }}</div>

        <div @click="handleMonthChange(true)" class="calendar__header__button">
          <b-icon icon="caret-right" size="is-medium" type="is-white"> </b-icon>
        </div>
      </div>

      <div class="calendar__body">
        <div class="calendar__body__weekdays">
          <div
            class="calendar__body__weekdays__weekday"
            v-for="(weekday, idx) of weekdays"
            :key="idx"
          >
            <p>{{ weekday }}</p>
          </div>
        </div>

        <div class="calendar__body__days">
          <div
            v-for="(row, idx) of [0, 1, 2, 3, 4, 5]"
            :key="idx"
            class="calendar__body__days__rows"
            style="height: 60px; display: flex"
          >
            <div
              v-for="(column, idx) of [0, 1, 2, 3, 4, 5, 6]"
              :key="idx"
              class="calendar__body__days__rows__cells"
              :id="`cell-${row}-${column}`"
              @click="setSelectedCell(row, column)"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from 'moment';

export default {
  name: 'events-calendar',
  props: {
    eventsIndicator: {
      type: Boolean,
      default: () => false,
    },
    disabledDates: {
      type: Array,
      default: () => [],
    },
    reloadCalendar: {
      type: Boolean,
      default: () => false,
    },
    selectFirstDate: {
      type: Boolean,
      default: () => false,
    },
  },

  data: () => ({
    currentMonth: moment().startOf('month'),
    minDate: moment(),
  }),
  watch: {
    disabledDates() {
      this.setDisabledDates();
    },
    reloadCalendar() {
      this.currentMonth = moment().startOf('month');

      this.$emit(
        'onCurrentMonthChange',
        this.currentMonth.format('YYYY-MM-DD')
      );

      this.setCalendar();
    },
  },
  computed: {
    days() {
      const daysInMonth = this.currentMonth.daysInMonth();
      const monthDays = [];

      for (let i = 1; i <= daysInMonth; i++) {
        if (i === 1)
          monthDays.push({
            day: this.currentMonth.format('DD'),
            weekday: this.currentMonth.weekday(),
          });
        else
          monthDays.push({
            day: this.currentMonth.add(1, 'day').format('DD'),
            weekday: this.currentMonth.weekday(),
          });
      }

      this.currentMonth.startOf('month');

      return monthDays;
    },
    weekdays() {
      return moment
        .weekdaysShort()
        .map((weekday) => weekday.charAt(0).toUpperCase() + weekday.slice(1));
    },
    canViewPreviousButton() {
      return this.currentMonth.isAfter(this.minDate);
    },
    viewTitle() {
      const monthTitle = this.currentMonth.format('MMMM YYYY');
      return monthTitle.charAt(0).toUpperCase() + monthTitle.slice(1);
    },
  },
  methods: {
    handleMonthChange(nextMonth = false) {
      if (nextMonth)
        this.currentMonth = this.currentMonth.clone().add(1, 'months');
      if (!nextMonth)
        this.currentMonth = this.currentMonth.clone().subtract(1, 'months');

      this.$emit(
        'onCurrentMonthChange',
        this.currentMonth.format('YYYY-MM-DD')
      );

      this.setCalendar();
    },
    removePreviousSelectCell() {
      const previousSelected = document.getElementsByClassName('selected')[0];

      if (previousSelected) {
        previousSelected.classList.remove('selected');
      }
    },
    disabledCell(row, column) {
      const element = document.getElementById(`cell-${row}-${column}`);
      return element.classList.contains('disabled-cell');
    },
    setSelectedCell(row, column) {
      if (!this.disabledCell(row, column)) {
        this.removePreviousSelectCell();

        const element = document.getElementById(`cell-${row}-${column}`);
        element.classList.add('selected');

        let currentMonth = this.currentMonth.month() + 1;
        currentMonth = currentMonth < 10 ? `0${currentMonth}` : currentMonth;

        const selectedDate = `${this.currentMonth.year()}-${currentMonth}-${
          element.innerText
        }`;

        this.$emit('onSelectDate', selectedDate);
      }
    },
    clearCalendar() {
      for (let row = 0; row < 6; row++) {
        for (let column = 0; column < 7; column++) {
          const element = document.getElementById(`cell-${row}-${column}`);
          element.innerText = '';
          element.classList.remove('disabled-cell');
          element.classList.remove('selected');
        }
      }
    },
    disableEmptyCells() {
      for (let row = 0; row < 6; row++) {
        for (let column = 0; column < 7; column++) {
          const element = document.getElementById(`cell-${row}-${column}`);
          if (element.innerText === '') {
            element.classList.add('disabled-cell');
          }
        }
      }
    },
    setFirstDateSelected() {
      let firstDateSelected = false;

      for (let row = 0; row < 6; row++) {
        for (let column = 0; column < 7; column++) {
          const element = document.getElementById(`cell-${row}-${column}`);

          if (
            !element.classList.contains('disabled-cell') &&
            !firstDateSelected
          ) {
            this.setSelectedCell(row, column);
            firstDateSelected = true;
          }
        }
      }
    },
    setDisabledDates() {
      for (let row = 0; row < 6; row++) {
        for (let column = 0; column < 7; column++) {
          const element = document.getElementById(`cell-${row}-${column}`);

          if (this.disabledDates.includes(element.innerText)) {
            element.classList.add('disabled-cell');
          }

          if (
            Number(element.innerText) < Number(this.minDate.format('DD')) &&
            this.minDate.month() === this.currentMonth.month()
          ) {
            element.classList.add('disabled-cell');
          }
        }
      }

      this.selectFirstDate && this.setFirstDateSelected();
    },
    setCalendar() {
      this.removePreviousSelectCell();

      this.clearCalendar();

      let dayIdx = 0;

      for (let row = 0; row < 6; row++) {
        let firstWeekday = this.days[dayIdx] ? this.days[dayIdx].weekday : 7;

        for (let column = firstWeekday; column < 7; column++) {
          document.getElementById(`cell-${row}-${column}`).innerText = this
            .days[dayIdx]
            ? this.days[dayIdx].day
            : '';

          dayIdx += 1;
        }
      }

      this.disableEmptyCells();
    },
  },
  mounted() {
    this.setCalendar();
  },
};
</script>

<style lang="scss" scoped>
.events-calendar {
  width: 100%;

  .calendar {
    width: 100%;
    &__header {
      padding: 10px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      background-color: var(--color-secondary);

      border-radius: 6px 6px 0 0;

      &__view-title {
        font-size: 1.3rem;
        letter-spacing: 1px;
        font-weight: bold;

        color: #fff;

        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
      }

      &__button:hover {
        cursor: pointer;
      }
    }

    &__body {
      &__weekdays {
        display: flex;
        justify-content: space-between;

        margin-top: 5px;

        &__weekday {
          background-color: #f5f9f9;
          border-radius: 4px;

          margin: 3px;

          width: 100%;
          text-align: center;
          letter-spacing: 1px;

          -webkit-user-select: none; /* Safari */
          -ms-user-select: none; /* IE 10 and IE 11 */
          user-select: none; /* Standard syntax */
        }

        &__weekday:last-child {
          border-right-width: 0.2px;
        }
      }

      &__days {
        &__rows {
          &__cells {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            font-size: 1.2rem;

            border-radius: 4px;
            background-color: #f5f9f9;

            margin: 3px;

            -webkit-user-select: none; /* Safari */
            -ms-user-select: none; /* IE 10 and IE 11 */
            user-select: none; /* Standard syntax */
          }

          &__cells:not(.disabled-cell):not(.selected):hover {
            cursor: pointer;
            color: var(--color-secondary);
          }

          &__cells.disabled-cell {
            background-color: #e0e0e0;

            cursor: not-allowed;
          }

          &__cells.selected {
            background-color: #47a5d7a6;
            color: #fff;
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>
