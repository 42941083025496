<template>
  <div class="clinic-selector-modal">
    <div class="modal-card">
      <header class="modal-card-head">
        <div class="modal-card-title">Selecionar clínica para acesso</div>
      </header>
      <section class="modal-card-body">
        <b-field>
          <b-select
            placeholder="Selecionar..."
            v-model="selectedClinic"
            icon="clinic-medical"
            expanded
          >
            <option
              v-for="(clinic, idx) in clinics"
              :value="clinic.id"
              :key="idx"
            >
              {{ clinic.name }}
            </option>
          </b-select>
        </b-field>
      </section>
      <footer class="modal-card-foot is-right">
        <b-button type="is-text" @click="$emit('close', false)"
          >Cancelar</b-button
        >

        <b-button
          type="is-primary"
          :disabled="!selectedClinic"
          @click="setDefaultClinic(selectedClinic)"
          >Entrar</b-button
        >
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SET_DEFAULT_CLINIC } from '@/store/actions/auth';

export default {
  data: () => ({
    selectedClinic: null,
  }),
  computed: {
    ...mapGetters(['clinics']),
  },
  methods: {
    setDefaultClinic(clinicId) {
      this.$store.dispatch(SET_DEFAULT_CLINIC, clinicId).then(() => {
        this.$emit('close', true);
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
