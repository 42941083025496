import Clear from '@/layouts/Clear.vue';

export default {
  path: '/profile',
  component: Clear,
  children: [
    {
      path: '',
      name: 'profile',
      component: () => import('@/views/Profile/DoctorProfile.vue'),
    }
  ],
};
