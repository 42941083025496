<template>
  <div>
    <b-button
      size="is-small"
      type="is-memed"
      icon-left="plus"
      id="memed-button"
      @click="onMemedButtonClick"
      :loading="isButtonDisabled"
      :disabled="isButtonDisabled"
    >
      Acessar Memed
    </b-button>
  </div>
</template>

<script>
import PrescriptionsService from '@/services/prescriptions.service';

export default {
  props: {
    patient: {
      type: Object,
      default: () => {},
    },
    scheduleId: Number,
    isLoading: Boolean,
    memedToken: String,
    doctorId: Number,
    clinicId: Number,
  },
  data: () => ({
    isButtonDisabled: true,
  }),
  methods: {
    onMemedButtonClick() {
      this.isButtonDisabled = true;

      window.MdHub.command
        .send('plataforma.prescricao', 'setPaciente', {
          cpf: this.patient.cpf,
          nome: this.patient.name,
          idExterno: this.patient.cpf,
          telefone: this.patient.phone,
          cidade: this.patient.address.city.name,
          endereco: `${this.patient.address.street}, ${
            this.patient.address.number
          } ${
            this.patient.address.complement
              ? ', ' + this.patient.address.complement
              : ','
          } ${this.patient.address.neighborhood}`,
        })
        .then(() => {
          window.MdHub.module.show('plataforma.prescricao');
          this.isButtonDisabled = false;
        });
    },
    initMemed() {
      const script = document.createElement('script');

      script.setAttribute('type', 'text/javascript');
      script.setAttribute('data-color', '#ff7677');
      script.setAttribute('data-token', this.memedToken);

      script.src = process.env.VUE_APP_MEMED_URL;

      script.onload = () => {
        window.MdSinapsePrescricao.event.add('core:moduleInit', (module) => {
          this.$emit('view', true);

          if (module.name === 'plataforma.prescricao') {
            this.isButtonDisabled = false;

            window.MdHub.command.send(
              'plataforma.prescricao',
              'setFeatureToggle',
              {
                deletePatient: false,
                removePatient: false,
                editPatient: false,
                removePrescription: false,
              }
            );

            window.MdSinapsePrescricao.command.send(
              'hub',
              'core:setDictionary',
              {
                protocolPlural: 'Modelos de receitas',
                protocolSingular: 'Modelo de receita',
                protocolSaved: 'Modelo salvo com sucesso!',
              }
            );

            window.MdHub.event.add('prescricaoImpressa', (prescriptionData) => {
              const { prescricao } = prescriptionData;
              const { documents, medicamentos } = prescricao;
              let prescription = '';
              let name = '';
              medicamentos.forEach((el) => {
                name += `${el.nome} `;
                prescription += `<p>${el.nome}</p>${el.posologia}<br/>`;
              });
              const data = {
                schedule_id: this.scheduleId ?? null,
                doctor_id: this.doctorId,
                patient_id: this.patient.id,
                clinic_id: this.clinicId,
                memed_prescription_id: documents[0].prescription_id,
                memed_file_type: medicamentos[0].receituario,
                prescription,
                name,
              };

              PrescriptionsService.saveMemedPrescription({
                ...data,
              });
            });
          }
        });

        window.MdSinapsePrescricao.event.add('core:moduleHide', (module) => {
          if (module.moduleName === 'plataforma.prescricao') {
            this.$emit('reload', true);
          }
        });
      };

      document.head.appendChild(script);
    },
  },
  mounted() {},
  beforeMount() {
    delete window.MdHub;
    this.initMemed();
  },
};
</script>

<style lang="scss" scoped></style>
