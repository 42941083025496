import Clear from '@/layouts/Clear.vue';

export default {
  path: '/timetable',
  component: Clear,
  children: [
    {
      path: 'doctor/:id',
      name: 'timetable.doctor',
      component: () => import('@/views/Doctors/Timetable.vue'),
    },
  ],
};
