import Clear from '@/layouts/Clear.vue';

export default {
  path: '/prescription-models',
  component: Clear,
  children: [
    {
      path: '',
      name: 'prescription-models',
      component: () =>
        import('@/views/PrescriptionModels/PrescriptionModelsList.vue'),
    },
    {
      path: 'create',
      name: 'prescription-models.create',
      component: () =>
        import('@/views/PrescriptionModels/PrescriptionModelForm.vue'),
    },
    {
      path: ':id/edit',
      name: 'prescription-models.edit',
      component: () =>
        import('@/views/PrescriptionModels/PrescriptionModelForm.vue'),
    },
  ],
};
