import Clear from '@/layouts/Clear.vue';

export default {
  path: '/schedules-justifications',
  component: Clear,
  children: [
    {
      path: '',
      name: 'schedules-justifications',
      component: () => import('@/views/ScheduleJustification/ScheduleJustificationList.vue'),
    },
    {
      path: 'create',
      name: 'schedules-justifications.create',
      component: () => import('@/views/ScheduleJustification/ScheduleJustificationForm.vue'),
    },
    {
      path: ':id',
      name: 'schedules-justifications.view',
      component: () => import('@/views/ScheduleJustification/ScheduleJustificationForm.vue'),
    },
  ],
};
