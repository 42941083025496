import Clear from '@/layouts/Clear.vue';

export default {
  path: '/vouchers',
  component: Clear,
  children: [
    {
      path: '',
      name: 'vouchers',
      component: () => import('@/views/Voucher/VoucherList.vue'),
    },
    {
      path: 'create',
      name: 'vouchers.create',
      component: () => import('@/views/Voucher/VoucherForm.vue'),
    },
    {
      path: ':id',
      name: 'vouchers.view',
      component: () => import('@/views/Voucher/VoucherForm.vue'),
    },
  ],
};
