<template>
  <div class="schedule-cancel-modal">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Remarcação de consulta</p>
        <button type="button" class="delete" @click="$emit('close', false)" />
      </header>
      <section class="modal-card-body">
        <b-loading :active.sync="isLoading" :is-full-page="false" />
        <div class="row">
          <div class="col-12">
            <ScheduleAvailabilities
              :doctorId="doctorId"
              :scheduleType="scheduleType"
              :procedureId="procedureId"
              @onSelectEvent="setSelectedDate"
              @onMonthChange="clearScheduleDate"
              @clearSelectedEvent="clearScheduleDate"
            />
          </div>
        </div>
      </section>
      <footer class="modal-card-foot is-right">
        <b-button @click="validateDate" type="is-primary">
          Remarcar consulta
        </b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import BuefyMixin from '@/mixins/BuefyMixin';
import moment from 'moment';
import SchedulesService from '../services/schedules.service';
import ScheduleAvailabilities from '@/components/ScheduleAvailabilities.vue';

export default {
  components: {
    ScheduleAvailabilities,
  },
  mixins: [BuefyMixin],
  props: {
    scheduleId: Number,
    scheduleType: Number,
    procedureId: Number,
    doctorId: Number,
  },
  data: () => ({
    isLoading: false,
    date: null,
    errors: {},
    time: 30,
    minDate: new Date(),
    forceSchedule: false,
  }),

  methods: {
    setSelectedDate(event) {
      const { start, standard_time, date } = event;
      const [hour, minutes] = start.split(':');

      this.date = moment(date).set('hour', hour).set('minutes', minutes);

      this.time = standard_time;
    },
    clearScheduleDate() {
      this.date = null;
      this.time = null;
    },
    validateDate() {
      this.isLoading = true;

      const { date, time } = this;
      const format = 'YYYY-MM-DD HH:mm:ss';
      const start = date ? moment(date).format(format) : '';
      const end = date ? moment(date).add(time, 'minute').format(format) : '';

      SchedulesService.validateDate({
        start: start,
        end: end,
        clinic_id: this.$store.getters.clinicId,
        doctor_id: this.doctorId,
      })
        .then(() => {
          this.isLoading = false;
          this.reschedule();
        })
        .catch(({ response }) => {
          const { status, data } = response;

          if (status === 412) {
            if (status === 412) {
              this.isLoading = false;
              this.$buefy.dialog.confirm({
                message: data.message,
                confirmText: 'Fechar',
                type: 'is-primary',
                canCancel: false,
              });
            }
          }
        });
    },

    reschedule() {
      this.isLoading = true;
      this.errors = {};

      const { date, time } = this;

      const format = 'YYYY-MM-DD HH:mm:ss';

      const start = date ? moment(date).format(format) : '';
      const end = date ? moment(date).add(time, 'minute').format(format) : '';

      SchedulesService.reschedule(this.scheduleId, {
        start,
        end,
      })
        .then(() => {
          this.$buefy.snackbar.open('Consulta remarcada com sucesso!');
          this.$emit('close', true);
        })
        .catch(({ response }) => {
          const { status, data } = response;

          if (status === 422 || status == 400) {
            this.$buefy.snackbar.open(
              'Verificar se todos os campos foram informados'
            );
            this.errors = data;
          } else {
            this.$buefy.snackbar.open('Erro ao remarcar consulta');
          }
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-card {
  width: 90vw;
  .modal-card-body {
    height: 90vh;

    .datepicker ::v-deep {
      font-size: 0.8rem !important;

      .timepicker .select select {
        font-size: 0.9rem;
      }

      .dropdown-content {
        padding: 0;
        margin: 0;
        margin-bottom: 5px;
      }

      .control.is-colon {
        font-size: 1.3rem;
        padding: 0;
      }

      .select:not(.is-multiple) {
        height: auto;
      }
    }
  }
}
</style>
