import Clear from '@/layouts/Clear.vue';

export default {
  path: '/service-locations',
  component: Clear,
  children: [
    {
      path: '',
      name: 'service-locations',
      component: () =>
        import('@/views/ServiceLocations/ServiceLocationsList.vue'),
    },
    {
      path: ':id?',
      name: 'service-locations.form',
      component: () =>
        import('@/views/ServiceLocations/ServiceLocationForm.vue'),
    },
  ],
};
