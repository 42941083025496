export default {
  SCHEDULED: 1,
  WAITING: 2,
  IN_PROGRESS: 3,
  FINISHED: 4,
  CANCELED: 5,
  RESCHEDULED: 6,
  NO_SHOW: 7,
  CONFIRMED: 8
};
