import Api from './api.service';

const ENDPOINT = 'service-locations';

export default {
  get: (params = {}) => Api.get(`${ENDPOINT}`, { params }),
  getId: (id) => Api.get(`${ENDPOINT}/${id}`),
  store: (data) => Api.post(`${ENDPOINT}`, data),
  update: (id, data) => Api.put(`${ENDPOINT}/${id}`, data),
  destroy: (id) => Api.delete(`${ENDPOINT}/${id}`),
  getCanCreateLocations: (clinicId) =>
    Api.get(`clinics/${clinicId}/can-create-locations`),
  getDoctorLocations: (clinicId, params = {}) =>
    Api.get(`clinics/${clinicId}/doctor-locations`, { params }),
  setDoctorLocations: (doctorId, data = {}) =>
    Api.patch(`${ENDPOINT}/${doctorId}/set-locations`, data),
};
