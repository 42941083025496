<template>
  <div class="schedule-cancel-modal">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Cancelamento de consulta</p>
        <button type="button" class="delete" @click="$emit('close', false)" />
      </header>
      <section class="modal-card-body">
        <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading>
        <div v-if="!isLoading" class="row mt-2">
          <div v-if="situation == 1" class="col-12">
            <b-message type="is-warning" :closable="false">
              Esta consulta já foi paga, o que deseja fazer? Enviar voucher para
              remarcação pelo paciente ou apenas cancelar? Lembrando que o
              cancelamento irá gerar o estorno automático para o paciente, e o
              voucher poderá ser recusado por ele, ocorrendo em seguida o
              estorno do valor pago
            </b-message>
          </div>

          <div v-if="situation == 2" class="col-12">
            <b-message type="is-warning" :closable="false">
              Deseja mesmo cancelar a consulta? O paciente receberá a
              notificação de cancelamento
            </b-message>
          </div>

          <div v-if="situation == 3" class="col-12">
            <b-message type="is-warning" :closable="false">
              Esta consulta foi marcada com voucher de 100%, o que deseja fazer?
              Você pode enviar um novo voucher para remarcação pelo paciente ou
              apenas cancelar
            </b-message>
          </div>

          <div class="col-12 mt-4">
            <b-field label="Selecione o motivo do cancelamento">
              <b-select
                v-model="justificationSelected"
                placeholder="Selecionar..."
                expanded
              >
                <option :value="null">Nenhum</option>
                <option
                  v-for="justification of this.justifications"
                  :key="justification.id"
                  :value="justification.id"
                >
                  {{ justification.justification }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="col-12 mt-4">
            <b-field label="Justificativa para o paciente">
              <b-input
                maxlength="200"
                type="textarea"
                v-model="justificationText"
              ></b-input>
            </b-field>
          </div>

          <div v-if="situation != 2" class="col-12 mt-4">
            <b-checkbox v-model="generateDiscount" type="is-secondary">
              Enviar cupom de 100% de desconto.
            </b-checkbox>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot is-right">
        <b-button @click="cancelSchedule" type="is-primary"
          >Cancelar consulta</b-button
        >
      </footer>
    </div>
  </div>
</template>

<script>
import SchedulesService from '@/services/schedules.service';

export default {
  props: {
    justifications: {
      type: Array,
      default: () => [],
    },
    schedule: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    situation: null,
    isLoading: false,
    justificationText: '',
    generateDiscount: false,
    justificationSelected: null,
  }),

  methods: {
    cancelSchedule() {
      const params = {
        generate_discount: this.generateDiscount,
        justification_id: this.justificationSelected,
        justification_text: this.justificationText,
        situation: this.situation,
      };
      this.$emit('close', params);
    },
  },
  mounted() {
    this.isLoading = true;

    SchedulesService.getScheduleCancelSituation(this.schedule.id)
      .then(({ data }) => {
        this.situation = data.situation;
      })
      .finally(() => (this.isLoading = false));
  },
};
</script>

<style lang="scss" scoped>
.info {
  text-align: justify;
  font-size: 1.2rem;
}
</style>
