import Clear from '@/layouts/Clear.vue';

export default {
  path: '/contact-us',
  component: Clear,
  children: [
    {
      path: '',
      name: 'contact-us',
      component: () => import('@/views/ContactUs.vue'),
    },
  ],
};
